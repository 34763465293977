import {FC, ReactElement} from "react"
import {Grid, Typography} from "@mui/material"
import BlockContainer from "../BlockContainer"
import {AddressInput} from "@kashalot-web/ui/dist"
import PlusButton from "../PlusButton"
import SwapButton from "../SwapButton"

interface IRouteBlock {
    route: Array<string>
    travelTime: number,
    travelLength: number
    pushEmptyPoint: () => void
    onChangeByIndex: (index: number, newAddress: string) => void
    suggestionsRequests: SuggestionsRequest[]
    pushSuggestionRequest: (id: string, inputValue: string) => void
    swapRoutePoints: (index1: number, index2: number) => void
}

const RouteBlock: FC<IRouteBlock> = (props): ReactElement => {
    const {
        route, travelTime, travelLength,
        suggestionsRequests, pushSuggestionRequest,
        onChangeByIndex, pushEmptyPoint, swapRoutePoints
    } = props

    return (
        <>
            <BlockContainer item container sx={{bgcolor: 'primary.dark'}} alignItems={"center"}>
                <Typography color={"white"} variant={"h5"}>Маршрут</Typography>
                <Typography color={"white"} variant={"h4"} sx={{m: '0 12px'}}>•</Typography>
                <Typography color={"white"}>Время в пути: &nbsp;{travelTime}мин</Typography>
                <Typography color={"white"} variant={"h4"} sx={{m: '0 12px'}}>•</Typography>
                <Typography color={"white"}>Расстояние: &nbsp;{travelLength}км</Typography>
            </BlockContainer>
            <BlockContainer item container sx={{bgcolor: 'primary.dark'}}>
                {route.map((address, ind) => {
                    return (
                        <Grid item key={`route-point-addres-asdkfm-${ind}`}>
                            <Grid
                                container
                                wrap={"nowrap"} alignItems={"center"}
                                sx={{maxHeight: '56px', overflow: 'hidden', mb: '8px'}}
                            >
                                <AddressInput
                                    id={`route-point-addres-lsadfl3-${ind}`}
                                    placeholder={"Введите адрес"}
                                    defaultValue={address}
                                    onChange={newAddress => onChangeByIndex(ind, newAddress)}
                                    suggestionsRequests={suggestionsRequests}
                                    pushSuggestionRequest={pushSuggestionRequest}
                                />
                                <Grid item sx={{m: '0 28px'}}>
                                    {ind === route.length - 1 ? (
                                            <PlusButton
                                                onClick={pushEmptyPoint}
                                            />
                                    ) : (
                                            <SwapButton
                                                onClick={() => swapRoutePoints(ind, ind + 1)}
                                            />
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                    )
                })}
            </BlockContainer>
        </>
    )
}

export default RouteBlock