import {FC, ReactElement} from "react"
import {Grid, Typography} from "@mui/material"

interface IOrderCardOptions {
    options: Array<Option | OptionWithPayload>
}

const OrderCardOptions: FC<IOrderCardOptions> = ({options}): ReactElement => {
    return (
        <Grid
            item container
        >
            <Typography>{options.map((o, ind) => ind === 0 ? o.title : o.title.toLowerCase()).join(', ')}</Typography>
        </Grid>
    )
}

export default OrderCardOptions