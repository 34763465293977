import {FC, ReactElement} from "react"
import {Grid, Paper, Typography} from "@mui/material"

interface IOfferCard {
    order: IOrder | IShippingOrder
    offer: IOffer
    onClick: () => void
}

const OfferCard: FC<IOfferCard> = ({offer, order, onClick}): ReactElement => {
    const isSelected = !!order.offersManager.selectedOffers.find(o => o.id === offer.id)
    const textColor = isSelected ? "white" : 'balck'

    let label: string = ""
    const fastOffer = order.offersManager.getFastOffers(1)
    if (fastOffer.length > 0 && offer.id === fastOffer[0].id) {
        label = "Быстрый"
    }
    const cheapOffer = order.offersManager.getCheapOffers(1)
    if (cheapOffer.length > 0 && offer.id === cheapOffer[0].id) {
        label = "Дешёвый"
    }

    return (
        <Paper
            component={Grid}
            item
            onClick={onClick}
            // container
            // direction={"column"}
            // wrap={"nowrap"}
            sx={{
                width: '350px',
                transition: (theme) => theme.transitions.create(
                    ['background-color'],
                    {duration: theme.transitions.duration.short}
                ),
                '&:hover': {
                    cursor: 'pointer',
                    bgcolor: isSelected ? 'primary.main' : 'ghostwhite'
                },
                bgcolor: isSelected ? 'primary.main' : undefined,
                borderRadius: '30px',
                p: '16px 24px'
            }}
        >
            <Grid container direction={"column"} wrap={"nowrap"}>
                <Grid
                    item container
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    wrap={"nowrap"}
                    sx={{mb: '16px'}}
                >
                    <Grid item>
                        <Typography color={textColor} variant={"h5"}>{label}</Typography>
                    </Grid>
                    <Grid item>
                        <Typography color={textColor} variant={"h3"}>{Math.floor(offer.minTime / 60)} мин</Typography>
                    </Grid>
                </Grid>
                <Grid
                    item container
                    justifyContent={"space-between"}
                    wrap={"nowrap"}
                    alignItems={"center"}
                    sx={{height: '75px'}}
                >
                    <Grid item>
                        <img src={offer.vendor.logo} width={72}/>
                    </Grid>
                    <Grid item>
                        <Grid container direction={"column"} wrap={"nowrap"}>
                            <Grid item>
                                <Typography variant={"subtitle1"} color={textColor}>{offer.vendor.name}</Typography>
                            </Grid>
                            <Grid item>
                                {order._type === "new_order" ? (
                                    <Typography color={textColor}>{order.orderDetails.carClass.title}</Typography>
                                ) : (
                                    <Typography color={textColor}>{order.orderDetails.courierClass.title}</Typography>
                                )}
                            </Grid>
                            <Grid item>
                                <Grid container wrap={"nowrap"}>
                                    <Typography color={textColor} variant={"h5"}>{offer.price}₽</Typography>
                                    &nbsp;&nbsp;&nbsp;
                                    <Typography color={textColor}>{offer.cashback}% кэшбэк</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                {order.offersManager.delayed && (
                    <Grid
                        item container
                        justifyContent={"space-between"}
                    >
                        <Grid item>
                            <Typography color={textColor} variant={"body1"}>Отложенная подача: </Typography>
                        </Grid>
                        <Grid item>
                            <Typography color={textColor} variant={"h5"}>+ {offer.delayedFeedMin} мин</Typography>
                        </Grid>
                    </Grid>
                )}
            </Grid>
        </Paper>
    )
}

export default OfferCard