import {FC, ReactElement} from "react"
import OffersBlock from "../../../../LocalUICore/desktop/NewOrderBlocks/OffersBlock"
import {useNewShipping} from "@kashalot-web/react/dist"

const OffersBlockContainer: FC = () : ReactElement => {
    const {shipping, selectOffer} = useNewShipping()

    return (
        <OffersBlock
            order={shipping}
            offers={shipping.offersManager.getOffers()}
            selectedOffers={shipping.offersManager.selectedOffers}
            selectOffer={offer => selectOffer(offer.id)}
            loading={shipping.status === "waiting-offers"}
        />
    )
}

export default OffersBlockContainer