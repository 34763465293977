import React, {FC, ReactElement} from "react"
import {Grid, Paper, Typography} from "@mui/material"
import Dot from "../../common/Dot"
import moment from "moment";

interface IOrdersBatchCard {
    ordersBatch: CreatedOrdersBatch
    onClickOrder: (order: CreatedOrder) => void
}

const OrdersBatchCard: FC<IOrdersBatchCard> = ({ordersBatch, onClickOrder}): ReactElement => {
    return (
        <Paper
            component={Grid}
            container
            direction="column"
            wrap="nowrap"
            sx={{p: '2vw 4vw', width: '100%'}}
        >
            <Grid item container wrap="nowrap" justifyContent="space-between" alignItems="center">
                <Grid item container direction="column" wrap="nowrap">
                    <Typography variant="subtitle1">{ordersBatch.deadline}</Typography>
                    <Typography variant="body1">{ordersBatch.title}</Typography>
                    <Typography variant="body1">
                        {ordersBatch.employee?.fio || ordersBatch.employee?.email}</Typography>
                </Grid>
                <Grid item />
            </Grid>
            <Grid item container direction={"column"} wrap={"nowrap"} sx={{m: '7px'}}>
                {ordersBatch.route.map((address: string, routePointIndex: number) => {
                    return (
                        <Grid
                            key={`delivery-order-route-point-${routePointIndex}`}
                            item container
                            alignItems="center"
                            wrap="nowrap"
                        >
                            <Grid item sx={{mr: '7px'}}><Dot size="14px"/></Grid>
                            <Grid item>
                                <Typography variant="body2">
                                    {address.split(',').filter((_, i) => i < 2).join(',')}
                                </Typography>
                            </Grid>
                        </Grid>
                    )
                })}
            </Grid>
            <Grid
                item container
                justifyContent={"space-around"}
            >
                {ordersBatch.orders.map(order => {
                    const durationMinutes = moment.duration(moment(order.deadline, "HH:mm DD.MM.YYYY").diff(moment(ordersBatch.deadline, "HH:mm DD.MM.YYYY"))).asMinutes()
                    return (
                        <Grid item key={order.id} sx={{maxWidth: '31%'}}>
                            <Paper
                                component={Grid}
                                container
                                direction={"column"}
                                wrap={"nowrap"}
                                alignItems={"center"}
                                onClick={() => onClickOrder(order)}
                                sx={{p: '3px'}}
                            >
                                <Grid item>
                                    <Typography align={"center"} noWrap={true}>+ {durationMinutes} мин</Typography>
                                </Grid>
                                <Grid item>
                                    <Typography align={"center"} noWrap={true}>{order.vendor.name}</Typography>
                                </Grid>
                                <Grid item>
                                    <Typography align={"center"}>{order.status.title}</Typography>
                                </Grid>
                                <Grid item>
                                    <Typography align={"center"}>{order.price}₽</Typography>
                                </Grid>
                            </Paper>
                            {/*<Grid*/}
                            {/*    container*/}
                            {/*    direction={"column"}*/}
                            {/*    wrap={"nowrap"}*/}
                            {/*    alignItems={"center"}*/}
                            {/*    onClick={() => onClickOrder(order)}*/}
                            {/*>*/}
                            {/*    <Grid item>*/}
                            {/*        <Typography align={"center"} noWrap={true}>{order.vendor.name}</Typography>*/}
                            {/*    </Grid>*/}
                            {/*    <Grid item>*/}
                            {/*        <Typography align={"center"}>{order.status.title}</Typography>*/}
                            {/*    </Grid>*/}
                            {/*    <Grid item>*/}
                            {/*        <Typography align={"center"}>{order.price}₽</Typography>*/}
                            {/*    </Grid>*/}
                            {/*</Grid>*/}
                        </Grid>
                    )
                })}
            </Grid>
        </Paper>
    )
}

export default OrdersBatchCard