import React, {FC, ReactElement, useState} from "react"
import DeadlinePanel from "../../../../LocalUICore/mobile/NewDeliveryPanels/DeadlinePanel"
import {LocalizationProvider, MobileDateTimePicker} from "@mui/lab"
import AdapterMoment from "@mui/lab/AdapterMoment"
import {useNewDelivery} from "@kashalot-web/react/dist"

interface IDeadlinePanelContainer {
    isOpen: boolean,
    open: () => void
    close: () => void
}

const DeadlinePanelContainer: FC<IDeadlinePanelContainer> = ({isOpen, open, close}): ReactElement => {
    const {delivery, updateDeliveryDetail} = useNewDelivery()

    const [isOpenPicker, setIsOpenPicker] = useState<boolean>(false)
    const openPicker = () => setIsOpenPicker(true)
    const closePicker = () => setIsOpenPicker(false)

    return (
        <>
            <DeadlinePanel
                open={open} close={close} isOpen={isOpen}
                isUrgent={delivery.isUrgent}
                deadline={delivery.deadline}
                selectIsUrgent={() => updateDeliveryDetail("isUrgent", true)}
                selectDeadline={() => {
                    updateDeliveryDetail("isUrgent", false)
                    openPicker()
                }}
            />
            <LocalizationProvider dateAdapter={AdapterMoment}>
                {/*TODO: изменение времени по закрытию окна*/}
                <MobileDateTimePicker
                    renderInput={() => <></>}
                    ampm={false}
                    disablePast
                    value={delivery.deadline}
                    onChange={(newDate: any) => updateDeliveryDetail("deadline", newDate)}
                    open={isOpenPicker}
                    onOpen={openPicker}
                    onClose={closePicker}
                />
            </LocalizationProvider>
        </>
    )
}

export default DeadlinePanelContainer