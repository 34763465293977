import React from 'react'
import "reflect-metadata"
import ReactDOM from 'react-dom'
import './index.css'
import {ThemeProvider} from "@mui/material/styles"
import reportWebVitals from './reportWebVitals'
import {LoadingScreen, mainTheme} from "@kashalot-web/ui/dist"
import AppWithStore from "./App"
import {createRoot} from "react-dom/client"

const root = createRoot(document.getElementById("root") as HTMLDivElement)

window.onresize = () => {
    let vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty('--vh', `${vh}px`)
}
window.onload = () => {
    let vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty('--vh', `${vh}px`)
}

root.render(
    <React.StrictMode>
        <ThemeProvider theme={mainTheme}>
            <LoadingScreen/>
        </ThemeProvider>
    </React.StrictMode>
)

if (window.navigator.onLine) {
    initApp()
}

// window.addEventListener('online', () => console.log('Became online'))
// window.addEventListener('offline', () => console.log('Became offline'))

function initApp() {
    // @ts-ignore
    if (window.ymaps) {
        // @ts-ignore
        ymaps.ready(async () => {
            // @ts-ignore
            // ymaps.modules.require(['route', 'geocode', 'suggest'])

            root.render(
                <React.StrictMode>
                    <AppWithStore/>
                </React.StrictMode>
            )
        })
    }
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
