import React, {FC, ReactElement} from "react"
import {Grid, Typography} from "@mui/material"

interface IHeaderRoute {
    onClick: () => void
    title: string
    placeholder: string
    labels: Array<string>
}

const HeaderRoute: FC<IHeaderRoute> = ({onClick, title, placeholder, labels}): ReactElement => {
    return (
        <Grid
            container
            alignItems="center"
            direction="column"
            wrap="nowrap"
            sx={{position: "absolute", top: 0, bgcolor: 'rgba(255,255,255,.3)'}}
            onClick={onClick}
        >
            <Typography variant="subtitle1">{title}</Typography>
            {labels.length === 0 && (
                <Typography variant="body1">{placeholder}</Typography>
            )}
            {labels.length !== 0 && (
                labels.map((label, index) => {
                    return (
                        <Typography variant="body1" key={`address-${index}`}>{label}</Typography>
                    )
                })
            )}
        </Grid>
    )
}

export default HeaderRoute