import {FC, ReactElement} from "react"
import CourierClassInput, {ICourierClassInput} from "../CourierClassInput"
import BlockContainer from "../BlockContainer"
import {Grid, TextField, Typography} from "@mui/material"
import CourierClasses from "@kashalot-web/core/dist/TaxiInfo/CourierClasses"
import AutoCourierTypeInput, {IAutoCourierTypeInput} from "../AutoCourierTypeInput"
import PortersInput, {IPortersInput} from "../PortersInput"

interface ICourierBlock extends ICourierClassInput, IAutoCourierTypeInput, IPortersInput {
    rentalTime: number
    onRentalTimeChange: (newRentalTime: number) => void
}

const CourierBlock: FC<ICourierBlock> = (props): ReactElement => {
    const {selectedCourierClass, rentalTime, onRentalTimeChange} = props

    return (
        <>
            <BlockContainer item container>
                <Typography variant={"h5"}>Курьер</Typography>
            </BlockContainer>
            <BlockContainer item container spacing={2}>
                <Grid item>
                    <CourierClassInput {...props} />
                </Grid>
                {selectedCourierClass.id === CourierClasses.AUTO_COURIER.id && (
                    <Grid item>
                        <AutoCourierTypeInput {...props} />
                    </Grid>
                )}
                {(selectedCourierClass.id === CourierClasses.KABLUK.id || selectedCourierClass.id === CourierClasses.TRUCK.id) && (
                    <>
                        <Grid item>
                            <PortersInput {...props} />
                        </Grid>
                        <Grid item>
                            <TextField
                                sx={{width: 150}}
                                label={"Время аренды, ч"}
                                type={"number"}
                                value={rentalTime / 60}
                                onChange={e => {
                                    try {
                                        const rentalTime = Number.parseInt(e.target.value)
                                        onRentalTimeChange(rentalTime * 60)
                                    } catch (e) {
                                    }
                                }}
                            />
                        </Grid>
                    </>
                )}
            </BlockContainer>
        </>
    )
}

export default CourierBlock