import React, {FC, ReactElement, useEffect, useState} from "react"
import {Fab, Grid, TextField, FilledInput, Typography, Box, ToggleButtonGroup, ToggleButton} from "@mui/material"
import {useCreatedOrders, useNewOrder, useOrdersRequest, useProfile} from "@kashalot-web/react/dist"
import SecondaryOrders from "../../../LocalUICore/desktop/MainPageBlocks/SecondaryOrders"
import NavigationButtons from "../../../LocalUICore/desktop/MainPageBlocks/NavigationButtons"
import {GET_CREATED_ORDERS_REQ_ID} from "../../../requestIDs"
import {useNavigate} from "react-router-dom"
import OrdersOutput from "../../../LocalUICore/desktop/MainPageBlocks/OrdersOutput"
import Statuses from "@kashalot-web/core/dist/TaxiInfo/Statuses"
import Clock from "../../../LocalUICore/desktop/Clock"
import SearchIcon from "@mui/icons-material/Search"
import share from "../../../LocalUICore/common/share"
import searchOrders from "../../../LocalUICore/common/SearchOrdersHook"
import {useSnackbar} from "notistack"

const CANCEL_ORDER_REQ_ID = "main-page-cancel-order-req-id"


const MainPageDesktop: FC = (): ReactElement => {
    const navigate = useNavigate()
    const {enqueueSnackbar} = useSnackbar()

    const {
        activeOrders, completedOrders, canceledOrders,
        cancelOrder,
    } = useCreatedOrders()
    const [cancelOrderStatus, cancelOrderRemove] = useOrdersRequest(CANCEL_ORDER_REQ_ID)
    useEffect(() => {
        if (cancelOrderStatus === "pending") {
            enqueueSnackbar('Заказа удаляется', {variant: 'default'})
        } else if (cancelOrderStatus === "success") {
            enqueueSnackbar('Заказа удален', {variant: 'success'})
            cancelOrderRemove()
        } else if (cancelOrderStatus === "error") {
            enqueueSnackbar('При удалении заказа произошла ошибка', {variant: 'error'})
            cancelOrderRemove()
        }
    }, [cancelOrderStatus])

    const {logout, userInfo} = useProfile()
    const {setReferenceOrder} = useNewOrder()

    const [actor] = useState<"client" | "dispatcher">(userInfo?.dispatcherID ? "dispatcher" : "client")

    const [gettingOrdersStatus] = useOrdersRequest(GET_CREATED_ORDERS_REQ_ID)

    const [searchValue, setSearchValue] = useState<string>("")


    const [futureOrders, setFutureOrder] = useState<Array<CreatedOrder | CreatedOrdersBatch>>([])
    const [inningsOrders, setInningsOrders] = useState<Array<CreatedOrder | CreatedOrdersBatch>>([])

    useEffect(() => {
        // if (gettingOrdersStatus === "success") {
        const inningsStatusesID = [
            Statuses.Status8, Statuses.Status9, Statuses.Status10,
            ...Statuses.completedStatuses(), ...Statuses.cancelingStatuses()
        ].map(s => s.id)
            const newFutureOrders: Array<CreatedOrder | CreatedOrdersBatch> = []
            const newInningsOrders: Array<CreatedOrder | CreatedOrdersBatch> = []
            activeOrders.forEach(order => {
                if (order._type === "created_order") {
                    if (inningsStatusesID.includes(order.status.id)) {
                        newInningsOrders.push(order)
                    } else {
                        newFutureOrders.push(order)
                    }
                } else if (order._type === "created_orders_batch") {
                    const isIncludeInningStatus = order.orders.reduce((acc, order) => {
                        return acc || inningsStatusesID.includes(order.status.id)
                    }, false)
                    if (isIncludeInningStatus) {
                        newInningsOrders.push(order)
                    } else {
                        newFutureOrders.push(order)
                    }
                }
            })
            setFutureOrder(newFutureOrders)
            setInningsOrders(newInningsOrders)
        // }
    }, [activeOrders, searchValue])

    return (
        <Grid
            container wrap="nowrap" justifyContent={"space-between"}
            sx={{bgcolor: 'primary.light', height: '100vh'}}
        >

            <Grid
                item container
                sx={{width: '6vw', pt: '3vh', pb: '12vh'}}
            >
                <NavigationButtons logout={logout} navigate={path => navigate(path)}/>
            </Grid>

            <Grid
                item container direction={"column"} wrap={"nowrap"}
                sx={{
                    width: '27vw', height: '100%',
                    //bgcolor: 'white',
                    p: '3vh 1vw',
                }}
            >
                <Clock/>
                <Grid item container sx={{minHeight: '26px', maxHeight: '26px', height: '26px'}}/>
                <OrdersOutput
                    actor={actor}
                    orders={searchOrders(futureOrders, searchValue)}
                    loading={gettingOrdersStatus === "pending"}
                    orderCardsType={"full"}
                    share={order => share(order)}
                    cancel={orderID => cancelOrder(orderID, CANCEL_ORDER_REQ_ID)}
                    replay={order => {
                        setReferenceOrder(order)
                        navigate('/create_order')
                    }}
                />
            </Grid>

            <Grid
                item container direction={"column"} wrap={"nowrap"}
                sx={{width: '42vw', p: '3vh 1vw', height: '100%'}}
            >
                <Grid item>
                    <TextField
                        name={"orders-search-input"}
                        fullWidth
                        placeholder="Вводите запрос"
                        value={searchValue}
                        onChange={e => setSearchValue(e.target.value)}
                        InputProps={{
                            sx: {bgcolor: 'white'},
                            endAdornment: (
                                <SearchIcon sx={{color: "lightgray"}}/>
                            )
                        }}
                    />
                </Grid>
                <Grid item container sx={{minHeight: '18px', maxHeight: '18px', height: '18px'}}/>
                <OrdersOutput
                    actor={actor}
                    orders={searchOrders(inningsOrders, searchValue)}
                    loading={gettingOrdersStatus === "pending"}
                    orderCardsType={"full"}
                    share={order => share(order)}
                    cancel={orderID => cancelOrder(orderID, CANCEL_ORDER_REQ_ID)}
                    replay={order => {
                        setReferenceOrder(order)
                        navigate('/create_order')
                    }}
                />
            </Grid>

            <Grid
                item container
                sx={{
                    bgcolor: 'white',
                    width: '25vw', height: '100%',
                    p: '3vh 1vw'
                }}
            >
                <SecondaryOrders
                    actor={actor}
                    completedOrders={searchOrders(completedOrders, searchValue)}
                    canceledOrders={searchOrders(canceledOrders, searchValue)}
                    loading={gettingOrdersStatus === "pending"}
                    onReplay={order => {
                        setReferenceOrder(order)
                        navigate('/create_order')
                    }}
                />
            </Grid>

        </Grid>
    )
}

export default MainPageDesktop