import React, {FC, ReactElement} from "react"
import BlockContainer from "../BlockContainer"
import {Autocomplete, Grid, TextField, Typography} from "@mui/material"

interface IOrganizationSelectorBlock {
    organizations: Array<Company | Branch | ShortCompany | ShortBranch>
    organization: Company | ShortCompany | Branch | ShortBranch | null
    selectOrganization: (organization: Company | Branch | ShortCompany | ShortBranch | null) => void
    loading: boolean
}

const OrganizationSelectorBlock: FC<IOrganizationSelectorBlock> = (props): ReactElement => {
    const {organization, organizations, loading, selectOrganization} = props

    return (
        <BlockContainer item container sx={{bgcolor: 'primary.dark'}}>
            <Grid item>
                <Grid container direction={"column"} wrap={"nowrap"} spacing={3}>
                    <Grid item>
                        <Typography variant="h5" color={"white"}>Заказчик</Typography>
                    </Grid>
                    <Grid item>
                        <Autocomplete
                            options={organizations}
                            isOptionEqualToValue={(option, value) => option.id === value?.id}
                            getOptionLabel={option => option.name}
                            value={organization}
                            onChange={(e, value) => {
                                selectOrganization(value)
                            }}
                            loading={loading}
                            sx={{width: 300}}
                            renderInput={(params) => {
                                return (
                                    <TextField
                                        {...params}
                                        InputProps={{...params.InputProps, sx: {bgcolor: 'white'}}}
                                        placeholder={"Введите название компании"}
                                    />
                                )
                            }}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </BlockContainer>
    )
}

export default OrganizationSelectorBlock