import {FC, ReactElement, useEffect} from "react"
import CreateDeliveryMobile from "./CreateDeliveryMobile"
import {useOrganization, useNewOrder} from "@kashalot-web/react"
import {useDispatcher, useNewDelivery, useProfile} from "@kashalot-web/react/dist"
import {Theme, useMediaQuery} from "@mui/material"
import CreateDeliveryDesktop from "./CreateDeliveryDesktop"
import {GET_CLIENTS_REQ_ID, GET_EMPLOYEES_REQ_ID, UPDATE_ORG_REQ_ID} from "../../requestIDs"
import EmployeeTypes from "@kashalot-web/core/dist/TaxiInfo/EmployeeTypes"


const CreateDeliveryPage: FC = (): ReactElement => {
    const {startListeningPrices, stopListeningPrices} = useNewOrder()
    const {updateDeliveryDetail, resetDelivery} = useNewDelivery()
    const {userInfo} = useProfile()
    const {getEmployeesList, getOrganizationDetails, employeesManager} = useOrganization()
    const {getClients} = useDispatcher()

    useEffect(() => {
        resetDelivery()
        updateDeliveryDetail("isUrgent", true)
        if (userInfo) {
            if ([EmployeeTypes.Dispatcher.id, EmployeeTypes.DispatcherWorker.id].includes(userInfo.employeeType.id)) {
                getClients(GET_CLIENTS_REQ_ID)
            } else if ([...EmployeeTypes.getBranchEmployees(), ...EmployeeTypes.getCompanyEmployees()]
                .map(e => e.id).includes(userInfo.employeeType.id)) {
                getOrganizationDetails(UPDATE_ORG_REQ_ID)
                getEmployeesList(GET_EMPLOYEES_REQ_ID)
            }
        }
        startListeningPrices()
        return () => stopListeningPrices()
    }, [])

    useEffect(() => {
        if (employeesManager && userInfo) {
            const targetEmployee = employeesManager.getActiveEmployees().find(employee => employee.id === userInfo.userID)
            if (targetEmployee) {
                const workAddress = targetEmployee.wishlist?.find(wishlistItem => wishlistItem.type === "work")
                if (workAddress) {
                    updateDeliveryDetail("comment", workAddress.comment)
                    updateDeliveryDetail("startAddress", workAddress.address)
                }
            }
        }
    }, [employeesManager])

    // const xl = useMediaQuery((theme: Theme) => theme.breakpoints.up('xl'))
    // const lg = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'))
    // const md = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'))
    const sm = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'))
    // const xs = useMediaQuery((theme: Theme) => theme.breakpoints.up('xs'))

    if (sm) {
        return (
            <CreateDeliveryDesktop />
        )
    }

    return (
        <CreateDeliveryMobile/>
    )
}

export default CreateDeliveryPage