import React, {FC, ReactElement, useState} from "react"
import {Grid, Skeleton, ToggleButton, ToggleButtonGroup, Typography} from "@mui/material"
import OrderCardShortDesktop from "../OrderCardDesktop/OrderCardShortDesktop"
import OrdersOutput from "./OrdersOutput"

interface ISecondaryOrders {
    actor: "dispatcher" | "client"
    completedOrders: Array<CreatedOrder | CreatedOrdersBatch>
    canceledOrders: Array<CreatedOrder | CreatedOrdersBatch>
    loading: boolean
    onReplay: (order: CreatedOrder) => void
}

const SecondaryOrders: FC<ISecondaryOrders> = (props): ReactElement => {
    const {actor, completedOrders, canceledOrders, loading, onReplay} = props
    const [ordersType, setOrdersType] = useState<"completedOrders" | "canceledOrders">("completedOrders")

    let orders: Array<CreatedOrder | CreatedOrdersBatch> = canceledOrders
    if (ordersType === "completedOrders") {
        orders = completedOrders
    }

    return (
        <Grid container item direction={"column"} wrap={"nowrap"} sx={{height: '100%'}}>
            <ToggleButtonGroup
                color="primary"
                value={ordersType}
                exclusive
                fullWidth
                onChange={(e, newValue) => {
                    if (newValue === "completedOrders" || newValue === "canceledOrders") {
                        setOrdersType(newValue)
                    }
                }}
            >
                {[
                    {value: "completedOrders" as "completedOrders", label: "Завершенные"},
                    {value: "canceledOrders" as "canceledOrders", label: "Отмененные"},
                ].map((item, ind) => {
                    return (
                        <ToggleButton
                            value={item.value}
                            key={`toggle-button-asdmfka${ind}`}
                            sx={{
                                // '& .Mui-selected': {
                                // color: 'white',
                                // bgcolor: 'pink'
                                // p: '30px'
                                // }
                            }}
                        >
                            {item.label}
                        </ToggleButton>
                    )
                })}
            </ToggleButtonGroup>
            <Grid item container sx={{minHeight: '26px', maxHeight: '26px', height: '26px'}} />
            <OrdersOutput
                actor={actor}
                orderCardsType={"short"}
                orders={orders}
                loading={loading}
                replay={onReplay}
            />
        </Grid>
    )
}
export default SecondaryOrders