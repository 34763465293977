import {FC, ReactElement} from "react"
import BlockContainer from "../BlockContainer"
import {Autocomplete, Grid, TextField, Typography} from "@mui/material"

interface ICompanyEmployeeBlock {
    loading: boolean
    organization: Company | ShortCompany | Branch | ShortBranch | null
    organizations: Array<Company | ShortCompany | Branch | ShortBranch>
    onChangeOrganization: (newOrg: Company | ShortCompany | Branch | ShortBranch | null) => void
    employee: Employee | null
    employees: Array<Employee>
    onChangeEmployee: (employee: Employee | null) => void
}

const CompanyEmployeeBlock: FC<ICompanyEmployeeBlock> = (props): ReactElement => {
    const {
        loading,
        organization, organizations, onChangeOrganization,
        employee, employees, onChangeEmployee
    } = props

    return (
        <>
            <BlockContainer item container>
                <Typography color={"white"} variant={"h5"}>Заказчик</Typography>
            </BlockContainer>
            <BlockContainer item container spacing={3}>
                <Grid item>
                    <Autocomplete
                        disablePortal
                        value={organization}
                        isOptionEqualToValue={(option, value) => option.id === value?.id}
                        options={organizations}
                        onChange={(e, value) => onChangeOrganization(value)}
                        getOptionLabel={option => option.name}
                        sx={{width: 300}}
                        loading={loading}
                        renderInput={(params) => {
                            return (
                                <TextField
                                    {...params}
                                    InputProps={{...params.InputProps, sx: {bgcolor: 'white'}}}
                                    placeholder={"Введите название компании"}
                                />
                            )
                        }}
                    />
                </Grid>
                <Grid item>
                    <Autocomplete
                        disablePortal
                        value={employee}
                        isOptionEqualToValue={(option, value) => option.id === value?.id}
                        options={employees}
                        onChange={(e, value) => onChangeEmployee(value)}
                        getOptionLabel={option => {
                            if (option.fio) {
                                return `${option.fio} (${option.email})`
                            }
                            return option.email
                        }}
                        sx={{width: 300}}
                        disabled={organization === null}
                        renderInput={(params) => {
                            return (
                                <TextField
                                    {...params}
                                    InputProps={{...params.InputProps, sx: {bgcolor: 'white'}}}
                                    placeholder={"Введите имя сотрудника"}
                                />
                            )
                        }}
                    />
                </Grid>
            </BlockContainer>
        </>
    )
}

export default CompanyEmployeeBlock