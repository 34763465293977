import React, {FC, ReactElement} from "react"
import {Divider, Grid, Radio, Typography} from "@mui/material"
import {CurtainBody, CurtainPanel} from "../CurtainPanel"

interface IPaymentTypePanel {
    isOpen: boolean
    open: () => void
    close: () => void
    paymentTypes: Array<PaymentType>
    selectedPaymentType: PaymentType
    selectPaymentType: (newPaymentType: PaymentType) => void
    selectedCashback: number
    setCashback: (cashback: number) => void
}

const PaymentTypePanel: FC<IPaymentTypePanel> = (props): ReactElement => {
    const {
        isOpen, open, close,
        paymentTypes, selectedPaymentType, selectPaymentType,
        setCashback, selectedCashback
    } = props

    return (
        <CurtainPanel
            open={open} close={close} isOpen={isOpen}
            iconContainerWidth="3.4rem"
            iconContainerHeight="2.7rem"
        >
            <CurtainBody>
                <Grid
                    container item
                    direction="column"
                    wrap="nowrap"
                >
                    <Grid item sx={{p: '0.3rem 5vw'}}>
                        <Typography variant="h5">
                            Тип оплаты
                        </Typography>
                    </Grid>
                    {paymentTypes.map((paymentType, index) => {
                        const isSelected = selectedPaymentType.id === paymentType.id
                        return (
                            <Grid
                                key={`employee-${index}`}
                                item container
                                wrap={"nowrap"} alignItems={"center"}
                                onClick={() => selectPaymentType(paymentType)}
                            >
                                <Grid item>
                                    <Radio checked={isSelected}/>
                                </Grid>
                                <Typography variant="body2">
                                    {paymentType.title}
                                </Typography>
                            </Grid>
                        )
                    })}

                    <Divider />
                    <Grid item sx={{p: '0.3rem 5vw'}}>
                        <Typography variant="h5">
                            Кэшбек
                        </Typography>
                    </Grid>
                    <Grid
                        item container wrap={"nowrap"} alignItems={"center"}
                        onClick={() => setCashback(0)}
                    >
                        <Grid item>
                            <Radio checked={selectedCashback === 0}/>
                        </Grid>
                        <Typography
                            variant="body2"
                        >
                            Корпоративный
                        </Typography>
                    </Grid>
                    <Grid
                        item container wrap={"nowrap"} alignItems={"center"}
                        onClick={() => setCashback(15)}
                    >
                        <Grid item>
                            <Radio checked={selectedCashback === 15}/>
                        </Grid>
                        <Typography
                            variant="body2"
                        >
                            Агентский 15%
                        </Typography>
                    </Grid>

                    <Grid item container sx={{height: '30px'}} />
                </Grid>
            </CurtainBody>
        </CurtainPanel>
    )
}

export default PaymentTypePanel