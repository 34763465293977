import React, {FC, ReactElement} from "react"
import BlockContainer from "../BlockContainer"
import {Grid, IconButton, Typography} from "@mui/material"
import ClearIcon from "@mui/icons-material/Clear"

interface ITitleBlock {
    title: string
    close: () => void
}

const TitleBlock: FC<ITitleBlock> = ({title, close}): ReactElement => {
    return (
        <BlockContainer item container justifyContent={"space-between"} sx={{bgcolor: 'primary.dark'}} alignItems={"flex-end"}>
            <Grid item>
                <Typography variant={"h3"} color={"white"}> {title} </Typography>
            </Grid>
            <Grid item>
                <IconButton onClick={close}>
                    <ClearIcon sx={{color: 'white'}} />
                </IconButton>
            </Grid>
        </BlockContainer>
    )
}

export default TitleBlock