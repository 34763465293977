import React, {FC, ReactElement, useState, Fragment} from "react"
import {Divider, Fab, Grid, TextField, Typography} from "@mui/material"
import SearchIcon from '@mui/icons-material/Search'
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck'
import AddIcon from '@mui/icons-material/Add'
import CancelIcon from '@mui/icons-material/Cancel'
// @ts-ignore
import {QuickScore} from "quick-score"
import {useStyles} from "./Styles"
import {CurtainBody, CurtainHeader, CurtainPanel, CurtainTab, ICurtainPanel} from "../CurtainPanel"
import DeliveryMemberListItem from "../DeliveryMemberListItem"

interface IUserListPanel extends ICurtainPanel {
    employees: Array<Employee>
    selectEmployee: (employee: Employee) => void
    selectedMembers: Array<DeliveryMember>
    selectMember: (member: DeliveryMember) => void
    updateMember: (newMember: DeliveryMember) => void
    removeMember: (targetMember: DeliveryMember) => void
    onClickAdd: () => void
}

const EmployeesPanel: FC<IUserListPanel> = (props): ReactElement => {
    const classes = useStyles()
    const {
        open, close, isOpen,
        employees, selectEmployee,
        selectedMembers, selectMember, removeMember, updateMember,
        onClickAdd
    }: IUserListPanel = props

    const [employeeName, setEmployeeName] = useState<string>("")
    const qsEmployees = new QuickScore(employees, ["fio", "email"])
    const filteredEmployees = qsEmployees.search(employeeName).map((result: any) => result.item)

    enum Tabs {Employees = "Employees", Selected = "Selected"}

    type TabTypes = Tabs.Employees | Tabs.Selected
    const [tab, openTab] = useState<TabTypes>(Tabs.Employees)

    return (
        <CurtainPanel
            open={open} close={close} isOpen={isOpen}
            iconContainerWidth="3.4rem"
            iconContainerHeight="2.7rem"
        >
            <CurtainHeader>
                <Grid
                    sx={{bgcolor: 'primary.main'}}
                    item container
                    direction="column"
                    className={classes.header}
                >
                    <Grid item sx={{p: '5px 9px'}}>
                        <TextField
                            autoComplete='off'
                            placeholder="Введите имя"
                            value={employeeName}
                            onChange={(event) => setEmployeeName(event.target.value)}
                            variant="standard"
                            fullWidth
                            InputProps={{
                                disableUnderline: true,
                                startAdornment: (
                                    <SearchIcon sx={{mr: '5px'}} />
                                )
                            }}
                        />
                    </Grid>
                </Grid>
            </CurtainHeader>
            <CurtainBody height='25rem'>
                <CurtainTab
                    isActive={tab === Tabs.Employees}
                    icon={
                        <SearchIcon
                            color="primary"
                            sx={{width: '70%', height: '70%'}}
                        />
                    }
                    onClickIcon={() => openTab(Tabs.Employees)}
                >
                    <Grid
                        container item
                        direction="column"
                        wrap="nowrap"
                        sx={{overflow: 'auto', height: '100%'}}
                    >
                        {filteredEmployees.length === 0 ? (
                            <Grid item>
                                <Typography
                                    variant="body2"
                                    sx={{padding: '10px'}}
                                >
                                    Сотрудники не найдены
                                </Typography>
                            </Grid>
                        ) : (
                            filteredEmployees.map((employee: Employee, index: number) => {
                                const selectedMember = selectedMembers.find((selectedMember: DeliveryMember) => selectedMember.id === employee.id)
                                return (
                                    <Fragment key={`employee-${employee.id}-${index}`}>
                                        <DeliveryMemberListItem
                                            employee={employee}
                                            deliveryMember={selectedMember}
                                            isSelect={!!selectedMember}
                                            onClick={() => {
                                                if (selectedMember) {
                                                    removeMember(selectedMember)
                                                } else {
                                                    selectEmployee(employee)
                                                }
                                            }}
                                            onAdd={() => !!selectedMember && updateMember({
                                                ...selectedMember,
                                                amount: selectedMember.amount + 1
                                            })}
                                            onSub={() => !!selectedMember && updateMember({
                                                ...selectedMember,
                                                amount: Math.max(selectedMember.amount - 1, 1)
                                            })}
                                        />
                                    </Fragment>
                                )
                            })
                        )}
                        <Grid item container sx={{minHeight: '70px', height: '70px', maxHeight: '70px'}} />
                        <Grid
                            container justifyContent="flex-end"
                            sx={{position: 'absolute', bottom: 0, left: 0, width: '100vw', p: '10px', pointerEvents: 'none'}}
                        >
                            <Fab color="primary" onClick={onClickAdd} sx={{pointerEvents: 'auto'}}>
                                <AddIcon/>
                            </Fab>
                        </Grid>
                    </Grid>
                </CurtainTab>
                <CurtainTab
                    isActive={tab === Tabs.Selected}
                    icon={
                        <PlaylistAddCheckIcon
                            color="primary"
                            sx={{width: '70%', height: '70%'}}
                        />
                    }
                    onClickIcon={() => openTab(Tabs.Selected)}
                >
                    <Grid
                        container item
                        direction="column"
                        wrap="nowrap"
                        sx={{overflow: 'auto', height: '100%'}}
                    >
                        {selectedMembers.length === 0 ? (
                            <Grid item>
                                <Typography
                                    variant="body2"
                                    sx={{padding: '10px'}}
                                >
                                    Никто не выбран
                                </Typography>
                            </Grid>
                        ) : (
                            selectedMembers.map((member, index) => {
                                return (
                                    <Fragment key={`selected-member-${index}`}>
                                        <Grid item container wrap="nowrap" justifyContent="space-between">
                                            <Grid
                                                item key={`employee-${member.id}-${index}`}
                                                onClick={() => selectMember(member)}
                                            >
                                                <Typography
                                                    variant="body2"
                                                    sx={{padding: '0px 9px', m: '6px 0'}}
                                                >
                                                    {member.fio || member.email} x{member.amount}
                                                </Typography>
                                                <Typography
                                                    variant="body2"
                                                    sx={{padding: '0px 9px', m: '6px 0'}}
                                                >
                                                    {member.phone}
                                                </Typography>
                                                <Typography
                                                    variant="body2"
                                                    sx={{padding: '0px 9px', m: '6px 0'}}
                                                >
                                                    {member.address.split(',').filter((_, i) => i < 2).join(',')}
                                                </Typography>
                                            </Grid>
                                            <Grid
                                                item container
                                                justifyContent="center" alignItems="center"
                                                sx={{width: '22vw'}}
                                                onClick={() => removeMember(member)}
                                            >
                                                <CancelIcon sx={{color: 'primary.main', width: '7vw', height: '10vw'}}/>
                                            </Grid>
                                        </Grid>
                                        <Divider/>
                                    </Fragment>
                                )
                            })
                        )}
                    </Grid>
                </CurtainTab>
            </CurtainBody>
        </CurtainPanel>
    )
}

export default EmployeesPanel