import React, {FC, ReactElement, useEffect, useState} from "react"
import UserListPanel from "../../../../LocalUICore/mobile/NewOrderPanels/UserListPanel"
import UserDetailsPanel from "../../../../LocalUICore/mobile/NewOrderPanels/UserDetailsPanel"
import useNewOrder from "@kashalot-web/react/dist/useNewOrder"
import {useDispatcher, useOrganization, useProfile} from "@kashalot-web/react/dist"
import OrganizationsListPanel from "../../../../LocalUICore/mobile/NewOrderPanels/OrganizationsListPanel"
import {GET_CLIENTS_REQ_ID} from "../../../../requestIDs"

interface IUserDetailsPanelContainers {
    isOpen: boolean,
    open: () => void
    close: () => void
}

const UserDetailsPanelContainers: FC<IUserDetailsPanelContainers> = ({isOpen, open, close}): ReactElement => {
    const {order, editOrderDetail} = useNewOrder()
    const {userInfo} = useProfile()
    const {getClients, clientsManager} = useDispatcher()
    const {employeesManager} = useOrganization()
    let employees: Array<Employee> = []
    if (userInfo) {
        if (userInfo.dispatcherID) {
            if (order.orderDetails.organization && clientsManager) {
                employees = clientsManager.getEmployeesByOrganizationID(order.orderDetails.organization.id, true)
            }
        } else {
            employees = employeesManager ? employeesManager.getActiveEmployees() : []
        }
    }

    useEffect(() => {
        if (userInfo && userInfo.dispatcherID) {
            getClients(GET_CLIENTS_REQ_ID)
        }
    }, [])

    const [isOpenEmployeesList, setIsOpenEmployeesList] = useState<boolean>(false)
    const openEmployeesList = () => setIsOpenEmployeesList(true)
    const closeEmployeesList = () => setIsOpenEmployeesList(false)
    const [isOpenOrganizationsList, setIsOpenOrganizationsList] = useState<boolean>(false)

    let organization: Company | ShortCompany | Branch | ShortBranch | null | undefined
    let openOrgList = undefined
    if (userInfo && userInfo.dispatcherID) {
        organization = order.orderDetails.organization
        openOrgList = () => setIsOpenOrganizationsList(true)
    }

    return (
        <>
            <UserListPanel
                isOpen={isOpenEmployeesList}
                open={openEmployeesList}
                close={closeEmployeesList}
                employees={employees}
                selectedEmployee={order.orderDetails.employee}
                selectEmployee={(employee) => {
                    editOrderDetail("employee", employee )
                    employee && editOrderDetail("customerPhoneNumber", employee.phone)
                }}
            />
            <OrganizationsListPanel
                isOpen={isOpenOrganizationsList}
                open={() => setIsOpenOrganizationsList(true)}
                close={() => setIsOpenOrganizationsList(false)}
                organizations={clientsManager?.getOrganizations() || []}
                selectedOrganization={order.orderDetails.organization}
                selectOrganization={org => {
                    editOrderDetail("organization", org )
                    editOrderDetail("employee", null)
                    editOrderDetail("customerPhoneNumber", "")
                }}
            />
            <UserDetailsPanel
                isOpen={isOpen}
                open={open}
                close={close}
                organizationName={organization === undefined ? organization : (organization?.name || "")}
                openOrganizationsList={openOrgList}
                employeeName={order.orderDetails.employee?.fio || order.orderDetails.employee?.email || ""}
                openEmployeesList={openEmployeesList}
                phoneNumber={order.orderDetails.customerPhoneNumber}
                editPhoneNumber={(newPhoneNumber) => editOrderDetail("customerPhoneNumber", newPhoneNumber)}
                orderTitle={order.orderDetails.customerName}
                editOrderTitle={(newOrderTitle) => editOrderDetail("customerName", newOrderTitle)}
                comment={order.orderDetails.customerComment}
                editComment={(newComment: string) => editOrderDetail("customerComment", newComment)}
            />
        </>
    )
}

export default UserDetailsPanelContainers