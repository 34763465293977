import React, {FC, ReactElement} from "react"
import BlockContainer, {BlockContainerWithSidePaddings} from "../BlockContainer"
import {Button} from "@mui/material"
import {ThemeProvider} from "@mui/material/styles"
import {primaryDarkerTheme} from "@kashalot-web/ui/dist"

interface IOrderButtonBlock {
    price: number
    onClick: () => void
}

const OrderButtonBlock: FC<IOrderButtonBlock> = ({price, onClick}): ReactElement => {
    return (
        <BlockContainerWithSidePaddings item container sx={{bgcolor: 'primary.dark'}} justifyContent={"flex-end"}>
            <ThemeProvider theme={primaryDarkerTheme}>
                <Button variant={"contained"} onClick={onClick}>{price}₽ • Заказать</Button>
            </ThemeProvider>
        </BlockContainerWithSidePaddings>
    )
}

export default OrderButtonBlock