import {FC, ReactElement, useEffect, useState} from "react"
import {useNewDelivery} from "@kashalot-web/react/dist"
import DeliveryOrderPanel from "../../../../LocalUICore/mobile/NewDeliveryPanels/DeliveryOrderPanel"
import OrdersPanel from "../../../../LocalUICore/mobile/NewDeliveryPanels/OrdersPanel"
import TaxiClasses from "@kashalot-web/core/dist/TaxiInfo/TaxiClasses"
import {TaxiOptions} from "@kashalot-web/core/dist/TaxiInfo/TaxiOptions"

interface IOrdersPanelContainer {
    isOpen: boolean,
    open: () => void
    close: () => void
}

const OrdersPanelContainer: FC<IOrdersPanelContainer> = ({isOpen, open, close}): ReactElement => {
    const {delivery, selectDeliveryOffer, dropMember, updateOrder, putMember, addCar, setOrderOptions, setOfferDelayedFeed} = useNewDelivery()

    const [targetOrder, setTargetOrder] = useState<IDeliveryOrder | null>(null)
    const [isOpenTargetOrder, setIsOpenTargetOrder] = useState<boolean>(false)
    useEffect(() => {
        if (targetOrder) {
            const order = delivery.orders?.find(o => o.key === targetOrder.key) || null
            setTargetOrder(order)
        }
    }, [delivery])
    useEffect(() => {
        if (targetOrder) {
            setIsOpenTargetOrder(true)
        }
    }, [targetOrder])
    useEffect(() => {
        if (!isOpenTargetOrder) {
            setTimeout(() => setTargetOrder(null), 1000*0.30)
        }
    }, [isOpenTargetOrder])

    if (delivery.orders) {
        return (
            <>
                {targetOrder && (
                    <DeliveryOrderPanel
                        deliveryOrder={targetOrder}
                        selectDeliveryOffer={selectDeliveryOffer}
                        setOfferDelay={setOfferDelayedFeed}
                        freeMembers={delivery.freeMembers}
                        removeMember={(member: DeliveryMember) => dropMember(targetOrder, member)}
                        putMembers={(selectedMembers: Array<DeliveryMember>) => putMember(targetOrder, selectedMembers)}
                        updateOrderTitle={(newTitle: string) => updateOrder(targetOrder, "customerName", newTitle)}
                        updateOrderPhone={newPhone => updateOrder(targetOrder, "phone", newPhone)}
                        taxiClasses={TaxiClasses.getAllClasses()}
                        selectTaxiClass={(newTaxiClass: TaxiClass) => updateOrder(targetOrder, "carClass", newTaxiClass)}
                        taxiOptions={TaxiOptions.getAllOptions()}
                        selectOptions={newOptions => setOrderOptions(targetOrder?.key, newOptions)}
                        open={() => setIsOpenTargetOrder(true)}
                        close={() => {
                            setIsOpenTargetOrder(false)
                            // setTimeout(() => setTargetOrder(null), 1000*0.25)
                        }}
                        isOpen={isOpenTargetOrder}
                    />
                )}
                <OrdersPanel
                    freeMembers={delivery.freeMembers}
                    addCar={addCar}
                    orders={delivery.orders}
                    // openOrderPanel={openOrderPanel}
                    openOrderPanel={(targetOrder) => {
                        setTargetOrder(targetOrder)
                        // setIsOpenTargetOrder(true)
                    }}
                    open={open}
                    close={close}
                    isOpen={isOpen}
                />
            </>
        )
    }

    return (
        <></>
    )
}

export default OrdersPanelContainer