import React, {FC, ReactElement} from "react"
import BlockContainer from "../BlockContainer"
import {Grid, TextField, Typography} from "@mui/material"
import {AddressInput} from "@kashalot-web/ui/dist"

interface IFilingAddressBlock {
    comment: string
    editComment: (newComment: string) => void
    address: string
    onChangeAddress: (newAddress: string) => void
    suggestionsRequests: SuggestionsRequest[]
    pushSuggestionRequest: (id: string, inputValue: string) => void
}

const FilingAddressBlock: FC<IFilingAddressBlock> = (props): ReactElement => {
    const {
        comment, editComment,
        address, onChangeAddress,
        pushSuggestionRequest, suggestionsRequests
    } = props

    return (
            <BlockContainer item container sx={{bgcolor: 'primary.dark'}}>
                <Grid item>
                    <Grid container spacing={4}>
                        <Grid item>
                            <Grid container direction={"column"} wrap={"nowrap"} spacing={3}>
                                <Grid item>
                                    <Typography variant="h5" color={"white"}>Адрес подачи</Typography>
                                </Grid>
                                <Grid item>
                                    <AddressInput
                                        id={`filing-address-asdfasf`}
                                        placeholder={"Введите адрес"}
                                        defaultValue={address}
                                        onChange={newAddress => onChangeAddress(newAddress)}
                                        suggestionsRequests={suggestionsRequests}
                                        pushSuggestionRequest={pushSuggestionRequest}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Grid container direction={"column"} wrap={"nowrap"} spacing={3}>
                                <Grid item>
                                    <Typography variant="h5" color={"white"}>Комментарий</Typography>
                                </Grid>
                                <Grid item>
                                    <TextField
                                        value={comment}
                                        onChange={e => editComment(e.target.value)}
                                        sx={{width: '400px'}}
                                        InputProps={{sx: {bgcolor: 'white'}}}
                                        placeholder={"Введите комментарий"}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </BlockContainer>
    )
}

export default FilingAddressBlock