import React, {FC, ReactElement, useState} from "react"
import {Grid, TextField, Typography} from "@mui/material"
// @ts-ignore
import {QuickScore} from "quick-score"
import {CurtainBody, CurtainHeader, CurtainPanel, ICurtainPanel} from "../CurtainPanel"

interface IOrganizationsListPanel extends ICurtainPanel {
    organizations: Array<Company | ShortCompany | Branch | ShortBranch>
    selectedOrganization: Company | ShortCompany | Branch | ShortBranch | null
    selectOrganization: (organization: Company | ShortCompany | Branch | ShortBranch | null) => void
}

const OrganizationsListPanel: FC<IOrganizationsListPanel> = (props): ReactElement => {
    const {
        isOpen, open, close,
        organizations, selectedOrganization, selectOrganization
    } = props

    const [searchValue, setSearchValue] = useState<string>("")
    const qsOrganizations = new QuickScore(organizations, ["name"])
    const filteredOrganizations = qsOrganizations.search(searchValue).map((result: any) => result.item)

    return (
        <CurtainPanel
            open={open} close={close} isOpen={isOpen}
            iconContainerWidth="3.4rem"
            iconContainerHeight="2.7rem"
        >
            <CurtainHeader>
                <Grid
                    sx={{
                        bgcolor: 'primary.main',
                        "& .MuiInputBase-root": {
                            color: 'white',
                            fontWeight: 400
                        }
                    }}
                    item container
                    direction="column"
                >
                    <Grid item sx={{p: '5px 9px'}}>
                        <TextField
                            autoComplete='off'
                            placeholder="Введите название"
                            value={searchValue}
                            onChange={(event) => setSearchValue(event.target.value)}
                            variant="standard"
                            fullWidth
                            InputProps={{disableUnderline: true}}
                        />
                    </Grid>
                </Grid>
            </CurtainHeader>
            <CurtainBody height='30rem'>
                <Grid
                    container item
                    direction="column"
                    wrap="nowrap"
                    sx={{overflow: 'auto', height: '100%'}}
                >
                    {filteredOrganizations.length === 0 ? (
                        <Grid item>
                            <Typography
                                variant="body2"
                                sx={{padding: '10px'}}
                            >
                                Компания не найдена
                            </Typography>
                        </Grid>
                    ) : (
                        filteredOrganizations.map((org, index) => {
                            const isSelected = !!selectedOrganization && org.id === selectedOrganization.id
                            const typographyProps = isSelected ? {color: "common.white"} : {}
                            return (
                                <Grid
                                    item key={`employee-${org.id}-${index}`}
                                    onClick={() => {
                                        selectOrganization(org)
                                        close()
                                        // if (isSelected) {
                                        //     selectEmployee(null)
                                        // } else {
                                        //     selectEmployee(employee)
                                        // }
                                    }}
                                    sx={isSelected ? {bgcolor: 'primary.main'} : {}}
                                >
                                    <Typography
                                        variant="body2"
                                        sx={{padding: '10px'}}
                                        {...typographyProps}
                                    >
                                        {org.name}
                                    </Typography>
                                </Grid>
                            )
                        })
                    )}
                    <Grid item sx={{minHeight: '3.4rem'}} />
                </Grid>
            </CurtainBody>
        </CurtainPanel>
    )
}

export default OrganizationsListPanel