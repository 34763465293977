import {FC, ReactElement} from "react"
import EmployeeBlock from "../../../../LocalUICore/desktop/NewOrderBlocks/EmployeeBlock"
import {useNewOrder, useOrganization} from "@kashalot-web/react/dist"

const EmployeeBlockContainer: FC = (): ReactElement => {
    const {employeesManager} = useOrganization()
    const {order, editOrderDetail} = useNewOrder()

    return (
        <EmployeeBlock
            employees={employeesManager ? employeesManager.getActiveEmployees() : []}
            selectedEmployee={order.orderDetails.employee}
            onChange={newEmployee => {
                editOrderDetail("employee", newEmployee )
                editOrderDetail("customerPhoneNumber", newEmployee?.phone || "")
            }}
        />
    )
}

export default EmployeeBlockContainer