import {FC, ReactElement} from "react"
import {Box, Chip, FormControl, InputLabel, MenuItem, OutlinedInput, Select} from "@mui/material"
import {TaxiOptions} from '@kashalot-web/core/dist/TaxiInfo/TaxiOptions'

export interface IOptionsInput {
    selectedOptions: Array<Option | OptionWithPayload>
    onOptionsChange: (newOptions: Array<Option | OptionWithPayload>) => void
}

const OptionsInput: FC<IOptionsInput> = ({selectedOptions, onOptionsChange}): ReactElement => {
    return (
        <FormControl sx={{width: 300}}>
            <InputLabel id="taxi-options-label">Опции</InputLabel>
            <Select
                labelId="taxi-options-label"
                multiple
                value={selectedOptions.map(o => o.id)}
                onChange={e => {
                    const options: Array<Option | OptionWithPayload> = []
                    const value = e.target.value
                    if (typeof value !== "string") {
                        value.forEach(optionID => {
                            const option = TaxiOptions.getAllOptions().find(o => o.id.toString() === optionID.toString())
                            if (option) {
                                options.push(option)
                            }
                        })
                    }
                    onOptionsChange(options)
                }}
                input={<OutlinedInput id="select-multiple-chip" label="Опции"/>}
                renderValue={selected => (
                    <Box sx={{display: 'flex', flexWrap: 'wrap', gap: 0.5}}>
                        {selected.map(value => {
                            const option = TaxiOptions.getAllOptions().find(o => o.id === value)
                            if (option) {
                                return (
                                    <Chip key={`selected-option-${option.id}`} label={option.title}/>
                                )
                            }
                        })}
                    </Box>
                )}
                // MenuProps={MenuProps}
            >
                {TaxiOptions.getAllOptions().map(option => (
                    <MenuItem
                        key={`option-${option.id}`}
                        value={option.id}
                        // style={getStyles(name, personName, theme)}
                    >
                        {option.title}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}

export default OptionsInput