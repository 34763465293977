import React, {useEffect} from 'react'
import {ThemeProvider} from "@mui/material/styles"
import {BrowserRouter as Router} from "react-router-dom"
import Routes from "./Routes"
import {useProfile, useMap} from "@kashalot-web/react"
import {mainTheme} from "@kashalot-web/ui"
import {LoadingScreen} from "@kashalot-web/ui/dist"
import {Provider} from "react-redux"
import store from '@kashalot-web/core/dist/State/Store'
import 'moment/locale/ru'
import moment from "moment"
import {SnackbarProvider} from "notistack"

function App() {
    moment.locale('ru')
    const {initialization, checkAuth} = useProfile()
    const {updateCenterByIP} = useMap()
    useEffect(() => {
        checkAuth()
        updateCenterByIP()
    }, [])
    return (
        <ThemeProvider theme={mainTheme}>
            <SnackbarProvider maxSnack={5}>
                <Router>
                    {initialization ? <Routes/> : <LoadingScreen/>}
                </Router>
            </SnackbarProvider>
        </ThemeProvider>
    )
}

function AppWithStore() {
    return (
        <Provider store={store}>
            <App/>
        </Provider>
    )
}

export default AppWithStore
