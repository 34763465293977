import {FC, ReactElement} from "react"
import {ICurtainPanel} from "../../../../LocalUICore/mobile/CurtainPanel"
import OrganizationsListPanel from "../../../../LocalUICore/mobile/NewOrderPanels/OrganizationsListPanel"
import {useDispatcher, useNewDelivery} from "@kashalot-web/react/dist"

const OrganizationsPanelContainer: FC<ICurtainPanel> = (props): ReactElement => {
    const {clientsManager} = useDispatcher()
    const {delivery, updateDeliveryDetail} = useNewDelivery()

    return (
        <OrganizationsListPanel
            {...props}
            organizations={clientsManager?.getOrganizations() || []}
            selectedOrganization={delivery.organization}
            selectOrganization={org => updateDeliveryDetail("organization", org)}
        />
    )
}

export default OrganizationsPanelContainer