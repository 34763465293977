import React, {FC, ReactElement, useEffect, useState} from "react"
import FullScreenContainer from "../../../LocalUICore/common/FullScreenContainer"
import YMapsBG from "../../../LocalUICore/mobile/IMapsBG"
import HeaderRoute from "../../../LocalUICore/mobile/HeaderRoute"
import {CircularProgress, Fab, Grid, Skeleton, Snackbar, TextField, Typography} from "@mui/material"
import IconButtonWithLabel from "../../../LocalUICore/mobile/IconButtonWithLabel"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import FastAndCheapContainer from "./PanelsContainers/FastAndCheapContainer"
import MainButton from "../../../LocalUICore/mobile/MainButton"
import {useNavigate} from "react-router-dom"
import PersonIcon from "@mui/icons-material/Person"
import HomeIcon from '@mui/icons-material/Home'
import WorkIcon from '@mui/icons-material/Work'
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar"
import TuneIcon from '@mui/icons-material/Tune'
import AutoModeIcon from '@mui/icons-material/AutoMode'
import RoutePanelContainer from "./PanelsContainers/RoutePanelContainer"
import {useMap, useMapAddressByCoordinates, useOrganization, useProfile, useNewOrder} from "@kashalot-web/react/dist"
import CarClassesPanelContainer from "./PanelsContainers/CarClassesPanelContainer"
import UserDetailsPanelContainers from "./PanelsContainers/UserDetailsPanelContainers"
import DatePanelContainer from "./PanelsContainers/DatePanelContainer"
import PaymentTypePanelContainer from "./PanelsContainers/PaymentTypePanelContainer"
import useCenter from '../../useCenter'
import useBackdrop from "../../../LocalUICore/common/useBackdrop"
import OptionsPanelContainer from "./PanelsContainers/OptionsPanelContainer"
import DelayedOptionsPanelContainer from "./PanelsContainers/DelayedOptionsPanelContainer"


const useWishlist = (wishlist: Array<WishlistItem>) => {
    const {getCoordinatesByAddress, coordinatesRequests, removeCoordinatesRequest} = useMap()

    const [localRequests, setLocalRequests] = useState<Array<{ id: string, type: WishlistItemType }>>([])
    const [homeCoordinates, setHomeCoordinates] = useState<[number, number] | null>(null)
    const [workCoordinates, setWorkCoordinates] = useState<[number, number] | null>(null)

    useEffect(() => {
        const reqs = wishlist.map((item, ind) => {
            const reqID = `get-coors-by-address-asd23f-${ind}`
            getCoordinatesByAddress(reqID, item.address)
            return {
                id: reqID,
                type: item.type
            }
        })
        setLocalRequests(reqs)
    }, [wishlist])

    useEffect(() => {
        const requestIDsForDelete: string[] = []
        localRequests.forEach(localRequest => {
            const req = coordinatesRequests.find(reqIT => reqIT.id === localRequest.id)
            if (req?.status === "success" || req?.status === "error") {
                if (req.status === "success" && req.coordinates) {
                    if (localRequest.type === "home") {
                        setHomeCoordinates(req.coordinates)
                    } else if (localRequest.type === "work") {
                        setWorkCoordinates(req.coordinates)
                    }
                }
                removeCoordinatesRequest(req.id)
                requestIDsForDelete.push(req.id)
            }
        })
        setLocalRequests(rs => rs.filter(r => !requestIDsForDelete.find(rfd => rfd === r.id)))
    }, [coordinatesRequests])

    const toArray = (): Array<{ coordinates: [number, number], type: "home" | "work" }> => {
        const points: Array<{ coordinates: [number, number], type: "home" | "work" }> = []
        if (homeCoordinates) {
            points.push({type: "home", coordinates: homeCoordinates})
        }
        if (workCoordinates) {
            points.push({type: "work", coordinates: workCoordinates})
        }
        return points
    }

    return {homeCoordinates, workCoordinates, toArray}
}


const getShortAddress = (routePoint: RoutePoint) => routePoint.address.split(',').filter((a, i) => i < 2).join(',')

enum Panels {Main, Route, Map, CarClass, Details, Date, Payment, Options, Delayed}

const CreateOrderMobileNew: FC = (): ReactElement => {
    const navigate = useNavigate()
    const {
        map,
        setMap,
        order,
        editRoutePointAtIndex,
        doOrder,
        editRoutePointAtIndexWithCoor,
        resetOrder
    } = useNewOrder()
    const {center, setCenter, centerType} = useMap()
    const {userInfo} = useProfile()
    const points = useWishlist(userInfo?.wishlist || [])
    const defaultCenter = useCenter()


    const [activePanel, setActivePanel] = useState<keyof Record<Panels, string>>(Panels.Main)


    const [isCenterUsed, setIsCenterUsed] = useState<boolean>(false)
    useEffect(() => {
        if (center && centerType === "geo" && !order.routeManager.route[0].address && !isCenterUsed) {
            setIsCenterUsed(true)
            editRoutePointAtIndexWithCoor(0, center)
        }
    }, [center])


    const [geoPosition, setGeoPosition] = useState<[number, number] | null>(null)


    const [snackbarError, setSnackbarError] = useState<boolean>(false)
    const closeSnackbarError = () => setSnackbarError(false)
    const [Backdrop, openBackdrop, closeBackdrop, isBackdropOpen] = useBackdrop()
    useEffect(() => {
        if (order.status === "ordering") {
            openBackdrop()
        } else {
            closeBackdrop()
        }
        if (order.status === "success") {
            resetOrder()
            navigate('/')
        } else if (order.status === "error") {
            setSnackbarError(true)
        }
    }, [order])


    const addressManager = useMapAddressByCoordinates("get-address-by-coors-asdnfln3nks")
    const [activeMapFieldInd, setActiveMapFieldInd] = useState<number>(-1)
    const [isMoved, setIsMoved] = useState<boolean>(false)
    const handleActiveMapMoved = (newCenterCoordinates: [number, number]) => {
        setIsMoved(false)
        if (activePanel === Panels.Map) {
            addressManager.getAddress(newCenterCoordinates)
        }
    }


    useEffect(() => {
        if (centerType === "default") {
            const point = order.routeManager.route.find(point => !!point.address && point.coordinates[0] !== -1)
            if (point) {
                setCenter(point.coordinates, "input")
            }
        }
    }, [order])


    return (
        <FullScreenContainer>
            <Backdrop/>

            <Snackbar
                open={snackbarError}
                onClose={closeSnackbarError}
                anchorOrigin={{vertical: "top", horizontal: "center"}}
                ContentProps={{sx: {bgcolor: 'white'}}}
                message={<Typography color={"error.dark"}>Ошибка! Попробуйте выбрать другого перевозчика</Typography>}
            />

            {defaultCenter ? (
                <YMapsBG
                    map={map}
                    setMap={setMap}
                    defaultCenter={defaultCenter}
                    isActive={activePanel === Panels.Map}
                    onCenterChange={coors => handleActiveMapMoved(coors)}
                    resetAddress={() => setIsMoved(true)}
                    points={points.toArray()}
                    geoPosition={coors => setGeoPosition(coors)}
                />
            ) : (
                <Grid
                    item container
                    sx={{height: '100%', bgcolor: 'lightgray'}}
                    direction={"column"} wrap={"nowrap"}
                    justifyContent={"center"} alignItems={"center"}
                >
                    <CircularProgress />
                    <Typography>Загрузка карты</Typography>
                </Grid>
            )}

            <HeaderRoute
                onClick={() => setActivePanel(Panels.Route)}
                title={"Маршрут:"}
                placeholder={"Указать маршрут"}
                labels={order.routeManager.routeReady() ? [
                    getShortAddress(order.routeManager.route[0]),
                    ...(order.routeManager.route.length > 2 ? ["..."] : []),
                    getShortAddress(order.routeManager.route[order.routeManager.route.length - 1]),
                ] : []}
            />

            <Grid
                container
                sx={{position: "absolute", bottom: 0, pointerEvents: 'none'}}
                direction="column"
                wrap="nowrap"
            >
                {activePanel === Panels.Map && (
                    <Grid
                        item container
                        wrap="nowrap"
                        direction="column"
                    >
                        <Grid item container wrap={"nowrap"} spacing={1} sx={{p: '5px'}} justifyContent={"center"}
                              alignItems={"flex-end"}>
                            {[
                                {
                                    icon: <HomeIcon/>, active: !!points.homeCoordinates && !!map,
                                    onClick: () => map.setCenter(points.homeCoordinates), size: "medium" as "medium"
                                },
                                {
                                    icon: <PersonIcon/>, active: !!geoPosition && !!map,
                                    onClick: () => map.setCenter(geoPosition), size: "large" as "large"
                                },
                                {
                                    icon: <WorkIcon/>, active: !!points.workCoordinates && !!map,
                                    onClick: () => map.setCenter(points.workCoordinates), size: "medium" as "medium"
                                },
                            ].map((item, ind) => {
                                if (item.active) {
                                    return (
                                        <Grid item key={`icon-pos-${ind}`}>
                                            <Fab
                                                color="primary" sx={{pointerEvents: 'auto'}} size={item.size}
                                                onClick={item.onClick}
                                            >
                                                {item.icon}
                                            </Fab>
                                        </Grid>
                                    )
                                }
                            })}
                        </Grid>
                        <Grid item container justifyContent={"center"} alignItems={"center"}
                              sx={{height: '50px', bgcolor: 'white'}}>
                            {addressManager.status === "success" && !isMoved ? (
                                <Typography align={"center"}>
                                    {addressManager.address}
                                </Typography>
                            ) : (
                                <Skeleton animation="wave" width='80%' height='80%'/>
                            )}
                        </Grid>
                        <MainButton
                            disabled={addressManager.status !== "success" || isMoved}
                            onClick={() => {
                                // editRoutePointAtIndex(activeMapFieldInd, addressManager.address)
                                if (addressManager.coordinates) {
                                    editRoutePointAtIndexWithCoor(activeMapFieldInd, addressManager.coordinates)
                                }
                                const amountEmptyAddress = order.routeManager.route
                                    .reduce((acc, curr, ind) => acc + (curr.address ? 0 : 1), 0)
                                setActivePanel(amountEmptyAddress === 1 ? Panels.Main : Panels.Route)
                            }}
                            label={"Подтвердить"}
                        />
                    </Grid>
                )}

                {activePanel !== Panels.Map && (
                    <>
                        <Grid
                            item container
                            wrap="nowrap"
                            direction="column"
                        >
                            <IconButtonWithLabel
                                icon={<ArrowBackIcon/>}
                                onClick={() => navigate('/')}
                                labels={[]}
                            />
                            <IconButtonWithLabel
                                icon={<PersonIcon sx={{color: 'white'}}/>}
                                onClick={() => setActivePanel(Panels.Details)}
                                labels={[
                                    (order.orderDetails.employee ? (order.orderDetails.employee.fio || order.orderDetails.employee.email) : "Кому"),
                                    (order.orderDetails.customerComment || "Ввести комментарий"),
                                    (order.orderDetails.customerName || "Ввести название"),
                                ]}
                            />
                            <IconButtonWithLabel
                                icon={<AutoModeIcon sx={{color: 'white'}}/>}
                                onClick={() => setActivePanel(Panels.Delayed)}
                                labels={[
                                    ("Автоназначение"),
                                    (order.offersManager.delayed?.maxDelayTime ? order.offersManager.delayed?.maxDelayTime + " мин" : "выкл")
                                ]}
                            />
                            <IconButtonWithLabel
                                icon={<TuneIcon sx={{color: 'white'}}/>}
                                onClick={() => setActivePanel(Panels.Options)}
                                labels={order.offersManager.options.length > 0
                                    ? order.offersManager.options.map(option => option.title)
                                    : ["Опции"]
                                }
                            />
                            <IconButtonWithLabel
                                icon={<DirectionsCarIcon sx={{color: 'white'}}/>}
                                onClick={() => setActivePanel(Panels.CarClass)}
                                labels={[order.orderDetails.carClass.title]}
                            />
                        </Grid>
                        {!order.routeManager.routeReady() && (
                            <MainButton
                                disabled={false}
                                onClick={() => setActivePanel(Panels.Route)}
                                label={"Указать маршрут"}
                                onClickDate={() => setActivePanel(Panels.Date)}
                                onClickPayment={() => setActivePanel(Panels.Payment)}
                            />
                        )}
                        {order.routeManager.routeReady() && (
                            <>
                                {/*{order.offersManager.getOffers().length > 0 && (*/}
                                <Grid item container sx={{pointerEvents: 'auto'}}>
                                    <FastAndCheapContainer/>
                                </Grid>
                                {/*)}*/}
                                <MainButton
                                    disabled={!order.validateOrder()}
                                    onClick={() => doOrder()}
                                    label={"Заказать"}
                                    onClickDate={() => setActivePanel(Panels.Date)}
                                    onClickPayment={() => setActivePanel(Panels.Payment)}
                                />
                            </>
                        )}
                    </>
                )}
            </Grid>

            <RoutePanelContainer
                isOpen={activePanel === Panels.Route}
                open={() => setActivePanel(Panels.Route)}
                close={() => setActivePanel(Panels.Main)}
                goToMap={ind => {
                    setActivePanel(Panels.Map)
                    setActiveMapFieldInd(ind)
                    editRoutePointAtIndex(ind, "")
                }}
            />

            <UserDetailsPanelContainers
                isOpen={activePanel === Panels.Details}
                open={() => setActivePanel(Panels.Details)}
                close={() => setActivePanel(Panels.Main)}
            />

            <CarClassesPanelContainer
                isOpen={activePanel === Panels.CarClass}
                open={() => setActivePanel(Panels.CarClass)}
                close={() => setActivePanel(Panels.Main)}
            />

            <DatePanelContainer
                isOpen={activePanel === Panels.Date}
                open={() => setActivePanel(Panels.Date)}
                close={() => setActivePanel(Panels.Main)}
            />

            <PaymentTypePanelContainer
                isOpen={activePanel === Panels.Payment}
                open={() => setActivePanel(Panels.Payment)}
                close={() => setActivePanel(Panels.Main)}
            />

            <DelayedOptionsPanelContainer
                isOpen={activePanel === Panels.Delayed}
                open={() => setActivePanel(Panels.Delayed)}
                close={() => setActivePanel(Panels.Main)}
            />

            <OptionsPanelContainer
                isOpen={activePanel === Panels.Options}
                open={() => setActivePanel(Panels.Options)}
                close={() => setActivePanel(Panels.Main)}
            />
        </FullScreenContainer>
    )
}

export default CreateOrderMobileNew