import {FC, ReactElement} from "react"
import OrderButtonBlock from "../../../../LocalUICore/desktop/NewOrderBlocks/OrderButtonBlock"
import {useNewOrder} from "@kashalot-web/react/dist"

const OrderButtonBlockContainer: FC = (): ReactElement => {
    const {order, doOrder} = useNewOrder()

    if (order.offersManager.selectedOffers.length === 0) {
        return (
            <></>
        )
    }

    const offerPrice = order.offersManager.selectedOffers[0].getPrice(order.offersManager.options)
    if (order.validateOrder() && order.offersManager.selectedOffers.length > 0 && offerPrice !== null) {
        return (
            <OrderButtonBlock price={offerPrice} onClick={doOrder}/>
        )
    }

    return (
        <></>
    )
}

export default OrderButtonBlockContainer