import {FC, ReactElement} from "react"
import InputMembersBlock from "../../../../LocalUICore/desktop/NewDeliveryBlocks/InputMembersBlock"
import {
    useDispatcher,
    useMap,
    useNewDelivery,
    useOrganization,
    useProfile
} from "@kashalot-web/react/dist"
import EmployeeTypes from "@kashalot-web/core/dist/TaxiInfo/EmployeeTypes"
import store from '@kashalot-web/core/dist/State/Store'

const GET_CLIENT_BY_ID_REQ_ID = "GET_CLIENT_BY_ID_REQ_ID-asdfasdf"

const InputMembersBlockContainer: FC = (): ReactElement => {
    const {delivery, updateMember, removeMember} = useNewDelivery()
    const {employeesManager} = useOrganization()
    const {suggestionsRequests, pushSuggestionRequest} = useMap()
    const {userInfo} = useProfile()
    const {clientsManager, getClientByID, removeRequest} = useDispatcher()

    let employees: Array<Employee> = []

    if (userInfo) {
        if ([EmployeeTypes.Dispatcher.id, EmployeeTypes.DispatcherWorker.id].includes(userInfo.employeeType.id)) {
            employees = clientsManager && delivery.organization ? clientsManager?.getEmployeesByOrganizationID(delivery.organization.id, true) : []
        } else if ([...EmployeeTypes.getBranchEmployees(), ...EmployeeTypes.getCompanyEmployees()]
            .map(e => e.id).includes(userInfo.employeeType.id)) {
            employees = employeesManager ? employeesManager.getActiveEmployees() : []
        }
    }

    const getFullEmployeeByID = (employeeID: string) => {
        return new Promise<Employee | null>((resolve, reject) => {
            getClientByID(employeeID, GET_CLIENT_BY_ID_REQ_ID)
            const un = store.subscribe(() => {
                const state: State = store.getState()
                const req = state.dispatcher.requests.find(r => r.id === GET_CLIENT_BY_ID_REQ_ID)
                if (req?.status === "success" && state.dispatcher.client?._type === "employee") {
                    resolve(state.dispatcher.client)
                    un()
                    removeRequest(GET_CLIENT_BY_ID_REQ_ID)
                } else if (req?.status !== "pending") {
                    resolve(null)
                    un()
                    removeRequest(GET_CLIENT_BY_ID_REQ_ID)
                }
            })
        })
    }

    return (
        <InputMembersBlock
            employeesList={employees}
            members={delivery.members}
            updateMember={updateMember}
            removeMember={removeMember}
            suggestionsRequests={suggestionsRequests}
            pushSuggestionRequest={pushSuggestionRequest}
            getFullEmployeeByID={userInfo?.dispatcherID ? getFullEmployeeByID : undefined}
        />
    )
}

export default InputMembersBlockContainer