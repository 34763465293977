import {FC, ReactElement, useEffect, useState} from "react"
import BlockContainer, {BlockContainerWithSidePaddings} from "../BlockContainer"
import {
    Box, Chip,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    TextField,
    Typography
} from "@mui/material"
import OffersBlock from "../NewOrderBlocks/OffersBlock"
import SwapButton from "../SwapButton"
import PlusButton from "../PlusButton"
import CarClassInput, {ICarClassInput} from "../CarClassInput"
import OptionsInput from "../OptionsInput"


const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 200,
        },
    },
}


interface IRoutesBlock {
    orders: Array<IDeliveryOrder>
    freeMembers: Array<DeliveryMember>
    putMember: (targetOrder: IDeliveryOrder, deliveryMembers: DeliveryMember[]) => void
    dropMember: (order: IDeliveryOrder, member: DeliveryMember) => void
    addCar: (deliveryMembers: DeliveryMember[]) => void
    swapOrderRoutePoints: (orderKey: string, index1: number, index2: number) => void
    selectDeliveryOffer: (order: IDeliveryOrder, offer: IOffer, type: ("cheap" | "fast")) => void
    allTaxiClasses: Array<TaxiClass>
    updateOrder: (order: IDeliveryOrder, paramType: "carClass" | "customerName" | "phone", newValue: any) => void
    setOrderOptions: (orderKey: string, newOptions: (Option | OptionWithPayload)[]) => void
}

const OrdersBlock: FC<IRoutesBlock> = (props): ReactElement => {
    const {
        orders, selectDeliveryOffer,
        freeMembers, putMember, dropMember, addCar,
        swapOrderRoutePoints, updateOrder,
        allTaxiClasses,
        setOrderOptions
    } = props

    const [selectedFreeMembers, setSelectedFreeMembers] = useState<Array<DeliveryMember>>([])


    return (
        <>
            {orders.map((order, ind) => {
                return (
                    <BlockContainerWithSidePaddings
                        key={`delivery-order-${ind}`}
                        container direction={"column"} wrap={"nowrap"}
                        sx={{bgcolor: ind % 2 === 0 ? 'primary.light' : ''}}
                    >
                        <BlockContainer container>
                            <Grid container>
                                <Typography variant={"h5"}>
                                    {order.orderDetails.deadline.format("HH:mm,  DD MMMM")} • {order.orderDetails.customerName}
                                </Typography>
                            </Grid>
                            <Grid container alignItems={"center"}>
                                <Typography variant={"h5"}>Маршрут</Typography>
                                <Typography variant={"h4"} sx={{m: '0 12px'}}>•</Typography>
                                <Typography>Время в
                                    пути: &nbsp;{order.routeManager.routeInfo.travelTime}мин</Typography>
                                <Typography variant={"h4"} sx={{m: '0 12px'}}>•</Typography>
                                <Typography>Расстояние: &nbsp;{order.routeManager.routeInfo.travelLength}км</Typography>
                            </Grid>
                        </BlockContainer>


                        <BlockContainer container>
                            {order.routeManager.route.map((routePoint, ind) => {
                                return (
                                    <Grid item key={`delivery-route-point-${ind}`}>
                                        <Grid container wrap={"nowrap"} alignItems={"center"}>
                                            <TextField
                                                value={routePoint.address}
                                                InputProps={{readOnly: true}}
                                            />
                                            {ind < order.routeManager.route.length - 1 && (
                                                <Grid item sx={{m: '0 26px'}}>
                                                    <SwapButton
                                                        onClick={() => swapOrderRoutePoints(order.key, ind, ind + 1)}
                                                        disabled={ind === 0}
                                                    />
                                                </Grid>
                                            )}
                                        </Grid>
                                    </Grid>
                                )
                            })}
                        </BlockContainer>


                        <BlockContainer container alignItems={"center"} spacing={2}>
                            <Grid item>
                                <TextField
                                    value={order.orderDetails.customerName}
                                    onChange={e => updateOrder(order, "customerName", e.target.value)}
                                    sx={{width: '280px'}}
                                    label={"Название заказа"}
                                />
                            </Grid>
                            <Grid item>
                                <FormControl>
                                    <InputLabel id={`members-on-order-${ind}`}>Пассажиры</InputLabel>
                                    <Select
                                        labelId={`members-on-order-${ind}`}
                                        id={`select-members-on-order-${ind}`}
                                        multiple
                                        value={order.membersList}
                                        input={<OutlinedInput id={`input-members-on-order-${ind}`} label="Пассажиры"/>}
                                        renderValue={(selected) => (
                                            <Box sx={{display: 'flex', flexWrap: 'wrap', gap: 0.5}}>
                                                {selected.map((member) => (
                                                    <Chip
                                                        onMouseDown={e => e.stopPropagation()}
                                                        key={member.id}
                                                        label={member.fio}
                                                        onDelete={() => dropMember(order, member)}
                                                    />
                                                ))}
                                            </Box>
                                        )}
                                        MenuProps={MenuProps}
                                    >
                                        {freeMembers.map((member: DeliveryMember) => (
                                            <MenuItem
                                                key={member.id}
                                                value={member.id}
                                                onClick={() => putMember(order, [member])}
                                            >
                                                {member.fio}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item>
                                <FormControl>
                                    <InputLabel id={`phone-on-order-${ind}`}>Телефон</InputLabel>
                                    <Select
                                        labelId={`phone-on-order-${ind}`}
                                        id={`select-phone-on-order-${ind}`}
                                        value={order.orderDetails.customerPhoneNumber}
                                        label={"Телефон"}
                                        onChange={e => updateOrder(order, "phone", e.target.value)}
                                    >
                                        {order.membersList.map((member: DeliveryMember) => (
                                            <MenuItem
                                                key={member.id}
                                                value={member.phone}
                                            >
                                                {member.phone}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item>
                                <CarClassInput
                                    allTaxiClasses={allTaxiClasses}
                                    selectedTaxiClass={order.orderDetails.carClass}
                                    onTaxiClassChange={carClass => updateOrder(order, "carClass", carClass)}
                                />
                            </Grid>
                            <Grid item>
                                <OptionsInput
                                    selectedOptions={order.offersManager.options}
                                    onOptionsChange={newOptions => setOrderOptions(order.key, newOptions)}
                                />
                            </Grid>
                            <Grid item>
                                <TextField
                                    value={order.membersList.reduce((acc, curr) => acc + curr.amount, 0)}
                                    InputProps={{readOnly: true}}
                                    label={"Пассажиров"}
                                    sx={{width: '110px'}}
                                />
                            </Grid>
                        </BlockContainer>


                        <OffersBlock
                            order={order}
                            offers={order.offersManager.getOffers()}
                            selectOffer={offer => selectDeliveryOffer(order, offer, "cheap")}
                            loading={order.status === "waiting-offers"}
                            showTitle={false}
                            showBGColor={false}
                            success={order.status === "success"}
                            ordering={order.status === "ordering"}
                        />
                    </BlockContainerWithSidePaddings>
                )
            })}
            {freeMembers.length > 0 && (
                <BlockContainerWithSidePaddings
                    container direction={"column"} wrap={"nowrap"}
                    sx={{bgcolor: orders.length % 2 === 0 ? 'primary.light' : ''}}
                >
                    <BlockContainer container>
                        <Typography variant={"h5"}>Добавить машину</Typography>
                    </BlockContainer>
                    <BlockContainer container alignItems={"center"} spacing={2}>
                        <Grid item>
                            <FormControl>
                                <InputLabel id={`free-members`}>Пассажиры</InputLabel>
                                <Select
                                    labelId={`free-members`}
                                    multiple
                                    value={selectedFreeMembers}
                                    input={<OutlinedInput id={`free-members-input`} label="Пассажиры"/>}
                                    renderValue={(selected) => (
                                        <Box sx={{display: 'flex', flexWrap: 'wrap', gap: 0.5}}>
                                            {selected.map((member) => (
                                                <Chip
                                                    onMouseDown={e => e.stopPropagation()}
                                                    key={member.id}
                                                    label={member.fio}
                                                    onDelete={() => {
                                                        setSelectedFreeMembers(selectedFreeMembers.filter(sm => sm.id !== member.id))
                                                    }}
                                                />
                                            ))}
                                        </Box>
                                    )}
                                    sx={{minWidth: '200px'}}
                                    MenuProps={MenuProps}
                                >
                                    {freeMembers
                                        .filter(m => !selectedFreeMembers.map(sm => sm.id).includes(m.id))
                                        .map((member: DeliveryMember) => (
                                            <MenuItem
                                                key={member.id}
                                                value={member.id}
                                                onClick={() => setSelectedFreeMembers(sms => [...sms, member])}
                                            >
                                                {member.fio}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item>
                            <PlusButton
                                onClick={() => {
                                    addCar(selectedFreeMembers)
                                    setSelectedFreeMembers(sms => [])
                                }}
                                disabled={selectedFreeMembers.length === 0}
                            />
                        </Grid>
                    </BlockContainer>
                </BlockContainerWithSidePaddings>
            )}
        </>
    )
}

export default OrdersBlock