import React, {FC, ReactElement} from "react"
import {Divider, Grid, Paper} from "@mui/material"
import OrderCardCover from "./OrderCardCover"
import moment from "moment"
import Statuses from '@kashalot-web/core/dist/TaxiInfo/Statuses'
import OrderCardTitle from "./OrderCardTitle"
import OrderCardRoute from "./OrderCardRoute"
import OrderCardTaxiInfo from "./OrderCardTaxiInfo"
import OrderCardOptions from "./OrderCardOptions"
import OrderCardCoprInfo from "./OrderCardCoprInfo"

interface IOrdersBatchCardDesktop {
    order: CreatedOrdersBatch
    type: 'full' | 'short'
    actor: "client" | "dispatcher"
    replay?: () => void
    cancel?: (orderID: string) => void
}

const isBatchHasActive = (order: CreatedOrdersBatch) => {
    return order.orders.reduce((acc, order) =>
        acc || Statuses.activeStatuses().map(s => s.id).includes(order.status.id), false)
}

const OrdersBatchCardDesktop: FC<IOrdersBatchCardDesktop> = (props): ReactElement => {
    const {
        type, actor,
        order,
        replay, cancel
    } = props

    return (
        <Paper
            component={Grid}
            container
            direction={"column"}
            wrap={"nowrap"}
            sx={{overflow: "hidden"}}
        >
            <OrderCardCover
                deadline={moment(order.deadline, "HH:mm DD.MM.YYYY").format("DD MMMM HH:mm")}
                bgcolor={isBatchHasActive(order) ? 'primary.main' : 'primary.dark'}
                replay={replay}
            />
            <Grid
                item container direction={"column"} wrap={"nowrap"}
                sx={{p: '5px 16px', bgcolor: type === 'short' ? 'primary.light' : ''}}
            >
                {[
                    <OrderCardTitle
                        title={order.title}
                    />,
                    <OrderCardRoute route={order.route} />,
                    <Divider sx={{width: '100%'}}/>,
                    ...(() => {
                        const result: Array<any> = []
                        order.orders.map(suborder => {
                            result.push(<OrderCardTaxiInfo
                                status={suborder.status}
                                auto={suborder.auto}
                                vendor={suborder.vendor}
                                cancel={cancel ? (() => cancel(suborder.id)) : undefined}
                            />)
                            result.push(<Divider sx={{width: '100%'}}/>)
                        })
                        return result
                    })(),
                    (order.options.length > 0 ? <OrderCardOptions options={order.options} /> : null),
                    (order.options.length > 0 ? <Divider sx={{width: '100%'}}/> : null),
                    <OrderCardCoprInfo
                        carClass={order.carClass}
                        price={Math.max(...order.orders.map(o => o.price))}
                        maxPrice={Math.max(...order.orders.map(o => o.price))}
                        minPrice={Math.min(...order.orders.map(o => o.price))}
                        paymentType={order.paymentType}
                        customerName={actor === "dispatcher"
                            ? order.organization?.name || ""
                            : order.employee?.fio || order.employee?.email || ""
                        }
                    />
                ].map((body, ind) => {
                    if (body) {
                        return (
                            <Grid item container sx={{m: '5px 0'}} key={`container-ind-asdfadf-${ind}`}>{body}</Grid>
                        )
                    }
                })}
            </Grid>
        </Paper>
    )
}

export default OrdersBatchCardDesktop