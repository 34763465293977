import {FC, PropsWithChildren, ReactElement, useState} from "react"
import {Backdrop, CircularProgress, Grid} from "@mui/material"

type IUseBackdrop = [
    FC,
    () => void,
    () => void,
    boolean
]

const useBackdrop = (): IUseBackdrop => {
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const open = () => setIsOpen(true)
    const close = () => setIsOpen(false)

    const BackdropComponent: FC<PropsWithChildren<unknown>> = ({children}): ReactElement => {
        return (
            <Backdrop
                sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                open={isOpen}
            >
                <Grid item container justifyContent="center" alignItems="center" direction="column">
                    <CircularProgress color="inherit"/>
                    <Grid item sx={{mt: '20px'}}>{children}</Grid>
                </Grid>
            </Backdrop>
        )
    }

    return [
        BackdropComponent,
        open,
        close,
        isOpen
    ]
}

export default useBackdrop