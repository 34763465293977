import React, {FC, ReactElement, ReactNode, useEffect, useState} from "react"
import {useStyles} from "./Styles"
import {Grid, TextField, Typography, Input, InputProps, OutlinedInput, Button, ButtonGroup} from "@mui/material"
import AddressesSuggestions from "../AddressesSuggestions"
import {CurtainBody, CurtainHeader, CurtainPanel, CurtainTab} from "../CurtainPanel"
import SearchIcon from "@mui/icons-material/Search"
import FavoriteIcon from "@mui/icons-material/Favorite"
import InputMask from 'react-input-mask'
import RoutePointInput from '../inputs/RoutPointInput'


interface IMemberDetailsPanel {
    member: DeliveryMember
    changeMemberDetails: (param: "address" | "phone" | "fio" | "amount", value: string) => void
    suggestionsRequest: SuggestionsRequest | null
    pushSuggestionRequest: (id: string, inputValue: string) => void
    open: () => void
    close: () => void
    isOpen: boolean
}

export const MemberDetailsPanel: FC<IMemberDetailsPanel> = (props): ReactElement => {
    const classes = useStyles()
    const {
        member, changeMemberDetails,
        suggestionsRequest, pushSuggestionRequest,
        open, close, isOpen,
    }: IMemberDetailsPanel = props

    const [destination, setDestination] = useState<string>("")
    const handleDestinationChange = (value: string) => {
        setDestination(value)
        pushSuggestionRequest(member.id, value)
    }

    useEffect(() => {
        if (isOpen) {
            setDestination(member.address)
        }
    }, [isOpen])

    // const isValid: boolean =
    //     ((!!suggestionsRequest && !!suggestionsRequest.suggestionsList.find(s => s.title === destination)) ||
    //     (!!member.wishlist && !!member.wishlist.find(s => s.address === destination))) &&
    //     phoneValidator(member.phone)

    const [activeAddressInput, setActiveAddressInput] = useState<boolean>(true)

    enum Tabs {Search = "Search", Wishlist = "Wishlist"}

    type TabTypes = Tabs.Search | Tabs.Wishlist
    const [tab, openTab] = useState<TabTypes>(Tabs.Search)

    return (
        <CurtainPanel
            open={open} close={() => {
            close()
            setDestination("")
        }} isOpen={isOpen}
            iconContainerWidth="3.4rem"
            iconContainerHeight="2.7rem"
        >
            <CurtainHeader>
                <Grid
                    sx={{bgcolor: 'primary.main'}}
                    item container
                    direction="column"
                    className={classes.header}
                >
                    <Grid
                        item
                        sx={{padding: '2px 20px'}}
                    >
                        <TextField
                            autoComplete='off'
                            value={member.fio}
                            placeholder={member.email || "Введите имя"}
                            onChange={e => changeMemberDetails("fio", e.target.value)}
                            fullWidth
                            variant="standard"
                            InputProps={{disableUnderline: true}}
                        />
                    </Grid>
                    <Grid
                        item
                        sx={{padding: '2px 20px'}}
                    >
                        <InputMask
                            mask="+7 (999) 999-9999"
                            value={member.phone ? member.phone : "+"}
                            onChange={(event) => changeMemberDetails("phone", event.target.value)}
                            children={((inputProps: any) =>
                                <TextField {...inputProps} variant="standard" InputProps={{disableUnderline: true}} type="tel" fullWidth/>
                            ) as unknown as ReactNode}
                        />
                        {/*{(inputProps: any) => <OutlinedInput {...inputProps} type="tel" fullWidth/>}*/}
                        {/*</InputMask>*/}
                    </Grid>
                    <Grid
                        item
                        sx={{padding: '2px 20px'}}
                    >
                        <RoutePointInput
                            value={destination}
                            onChange={value => handleDestinationChange(value)}
                            onFocus={() => setActiveAddressInput(true)}
                            onBlur={() => setTimeout(() => setActiveAddressInput(false), 100)}
                            placeholder="Начните вводить адрес"
                            deleteField={() => handleDestinationChange("")}
                        />
                    </Grid>
                    <Grid
                        item
                        sx={{padding: '2px 20px'}}
                    >
                        <TextField
                            autoComplete='off'
                            value={member.amount}
                            type={"number"}
                            fullWidth
                            variant="standard"
                            InputProps={{
                                readOnly: true,
                                disableUnderline: true,
                                endAdornment: (
                                    <ButtonGroup variant="contained">
                                        <Button
                                            sx={{
                                                width: '15vw',
                                                bgcolor: 'primary.light', color: 'black', '&:focus': {bgcolor: 'primary.light'}
                                            }}
                                            onClick={() => {
                                                changeMemberDetails("amount", (member.amount + 1).toString())
                                            }}
                                        >+</Button>
                                        <Button
                                            sx={{
                                                width: '15vw', bgcolor: 'primary.light', color: 'black', '&:focus': {bgcolor: 'primary.light'}
                                            }}
                                            onClick={() => {
                                                changeMemberDetails("amount", (Math.max(member.amount - 1, 1)).toString())
                                            }}
                                        >-</Button>
                                    </ButtonGroup>
                                )
                            }}
                        />
                    </Grid>
                </Grid>
            </CurtainHeader>
            <CurtainBody height='21rem'>
                <CurtainTab
                    isActive={tab === Tabs.Search}
                    icon={
                        <SearchIcon
                            color="primary"
                            sx={{width: '70%', height: '70%'}}
                        />
                    }
                    onClickIcon={() => openTab(Tabs.Search)}
                >

                    <Grid
                        container item
                        // className={classes.body}
                        direction="column"
                        wrap="nowrap"
                        // sx={{overflow: 'auto'}}
                    >
                        <AddressesSuggestions
                            activeAddressInput={activeAddressInput}
                            suggestionsRequest={suggestionsRequest}
                            setDestination={(address: string) => {
                                setDestination(address)
                                changeMemberDetails("address", address)
                            }}
                        />
                    </Grid>
                </CurtainTab>
                <CurtainTab
                    isActive={tab === Tabs.Wishlist}
                    icon={
                        <FavoriteIcon
                            color="primary"
                            sx={{width: '70%', height: '70%'}}
                        />
                    }
                    onClickIcon={() => openTab(Tabs.Wishlist)}
                >
                    <Grid
                        container item
                        // className={classes.body}
                        direction="column"
                        wrap="nowrap"
                        // sx={{overflow: 'auto'}}
                    >
                        {!member.wishlist && (
                            <Grid item>
                                <Typography
                                    variant="body2"
                                    sx={{padding: '10px'}}
                                >
                                    Не удалось загрузить избранные
                                </Typography>
                            </Grid>
                        )}
                        {member.wishlist && member.wishlist.length === 0 && (
                            <Grid item>
                                <Typography
                                    variant="body2"
                                    sx={{padding: '10px'}}
                                >
                                    Избранные отсутсвуют
                                </Typography>
                            </Grid>
                        )}
                        {member.wishlist && member.wishlist.length > 0 && (
                            member.wishlist.map((wishlistItem, index) => {
                                return (
                                    <Grid
                                        item key={`wishlist-item-${index}`}
                                        onClick={() => {
                                            setDestination(wishlistItem.address)
                                            changeMemberDetails("address", wishlistItem.address)
                                        }}
                                    >
                                        <Typography
                                            variant="body2"
                                            sx={{padding: '10px'}}
                                        >
                                            {wishlistItem.address}
                                        </Typography>
                                    </Grid>
                                )
                            })
                        )}
                    </Grid>
                </CurtainTab>
            </CurtainBody>
        </CurtainPanel>
    )
}