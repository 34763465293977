import {FC, ReactElement} from "react"
import {FormControl, InputLabel, MenuItem, Select} from "@mui/material"

export interface ICarClassInput {
    selectedTaxiClass: TaxiClass
    allTaxiClasses: Array<TaxiClass>
    onTaxiClassChange: (newTaxiClass: TaxiClass) => void
}

const CarClassInput: FC<ICarClassInput> = ({selectedTaxiClass, allTaxiClasses, onTaxiClassChange}): ReactElement => {
    return (
        <FormControl>
            <InputLabel id="taxi-classes">Тип автомобиля</InputLabel>
            <Select
                labelId="taxi-classes"
                label="Тип автомобиля"
                value={selectedTaxiClass.id}
                onChange={e => {
                    const newCarClass = allTaxiClasses.find(carClass => carClass.id === e.target.value)
                    if (newCarClass) {
                        onTaxiClassChange(newCarClass)
                    }
                }}
                sx={{width: '200px'}}
            >
                {allTaxiClasses.map((taxiClass, index) => {
                    return (
                        <MenuItem key={`taxi-class-${index}`} value={taxiClass.id}>{taxiClass.title}</MenuItem>
                    )
                })}
            </Select>
        </FormControl>
    )
}

export default CarClassInput