import React, {FC, ReactElement} from "react"
import {useStyles} from "./Styles"
import {Checkbox, Fab, Grid, Radio, Typography} from "@mui/material"
import {IOfferCard} from "./Type"
import RemoveIcon from "@mui/icons-material/Remove"
import AddIcon from '@mui/icons-material/Add'

export const OfferCard: FC<IOfferCard> = ({offer, onClick, isSelected, delayed, setDelay}): ReactElement => {
    const classes = useStyles()

    return (
        <Grid
            item container
            className={classes.root}
            alignItems='center'
            onClick={onClick}
            wrap="nowrap"
        >
            <Grid item>
                {delayed ? (
                    <Checkbox checked={isSelected}/>
                ) : (
                    <Radio checked={isSelected}/>
                )}
            </Grid>
            <Grid item>
                <Grid
                    container
                    sx={{width: '16vw', height: '16vw'}}
                    justifyContent="center"
                    alignItems="center"
                >
                    <Grid
                        item
                        className={classes.logoContainer}
                    >
                        <img className={classes.logo} src={offer.vendor.logo} alt=""/>
                    </Grid>
                </Grid>
            </Grid>
            <Grid
                item container
                direction="column"
            >
                <Grid item sx={{mb: '-6px'}}>
                    <Typography variant="body1">
                        {offer.vendor.name}
                    </Typography>
                </Grid>
                <Grid container item alignItems="center" sx={{mb: '-6px'}}>
                    <Grid item>
                        <Typography variant="body1">
                            {offer.carClass.title}
                        </Typography>
                    </Grid>
                    &nbsp;·&nbsp;
                    <Grid item>
                        <Typography variant="body1">
                            {(offer.minTime / 60).toFixed()} мин.
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container item alignItems="center">
                    <Grid item>
                        <Typography variant="body1">
                            {offer.price}₽
                        </Typography>
                    </Grid>
                    {offer.cashback > 0 && (
                        <>
                            &nbsp;·&nbsp;
                            <Typography variant="body1">
                                Кэшбек:
                            </Typography>
                            &nbsp;
                            <Typography variant="body1">
                                {offer.cashback}%
                            </Typography>
                        </>
                    )}
                </Grid>
            </Grid>
            {delayed && setDelay !== undefined && (
                <Grid
                    item container
                    direction={"column"}
                    wrap={"nowrap"}
                    spacing={0.5}
                >
                    <Grid item>
                        <Typography variant={"body2"} align={"center"} noWrap={true}>Отлож. подача</Typography>
                    </Grid>
                    <Grid item container justifyContent={"center"} alignItems={"center"}>
                        <Grid
                            item container
                            alignItems={"center"} justifyContent={"center"}
                            sx={{
                                bgcolor: 'primary.main',
                                borderRadius: '50px',
                                height: '35px',
                                width: '35px'
                            }}
                            onClick={event => {
                                event.stopPropagation()
                                setDelay(Math.max(offer.delayedFeedMin - 5, 0))
                            }}
                        >
                            <RemoveIcon sx={{color: 'white'}}/>
                        </Grid>
                        <Grid item sx={{m: '4px'}}>
                            {offer.delayedFeedMin} мин.
                        </Grid>
                        <Grid
                            item container
                            alignItems={"center"} justifyContent={"center"}
                            sx={{
                                bgcolor: 'primary.main',
                                borderRadius: '50px',
                                height: '35px',
                                width: '35px'
                            }}
                            onClick={event => {
                                event.stopPropagation()
                                setDelay(offer.delayedFeedMin + 5)
                            }}
                        >
                            <AddIcon sx={{color: 'white'}}/>
                        </Grid>
                    </Grid>
                </Grid>
            )}
        </Grid>
    )
}

export default OfferCard