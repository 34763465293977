import {FC, ReactElement} from "react"
import AddIcon from "@mui/icons-material/Add"
import {Fab} from "@mui/material"

interface ICrossButton {
    onClick: () => void
}

const CrossButton: FC<ICrossButton> = ({onClick}): ReactElement => {
    return (
        <Fab
            color={"primary"} size={"small"}
            onClick={onClick}
            sx={{
                bgcolor: 'primary.dark',
                '.MuiSvgIcon-root': {
                    transform: 'rotate(45deg)',
                    transition: (theme) => theme.transitions.create(
                        ['transform'],
                        {duration: theme.transitions.duration.complex}
                    )
                },
                '&:hover': {
                    bgcolor: 'primary.dark',
                    '.MuiSvgIcon-root': {
                        transform: 'rotate(135deg)'
                    },
                }
            }}
        >
            <AddIcon />
        </Fab>
    )
}

export default CrossButton