import {useEffect, useState} from "react"
import {useMap} from "@kashalot-web/react/dist"

const useCenter = () => {
    const {center, setCenter, gettingCenterStatus} = useMap()


    const [defaultCenter, setDefaultCenter] = useState<[number, number] | null>(null)
    const [waitingIP, setWaitingIP] = useState<boolean>(false)


    useEffect(() => {
        navigator.geolocation.getCurrentPosition(position => {
            setCenter([position.coords.latitude, position.coords.longitude], "geo")
            // setGeoPosition([position.coords.latitude, position.coords.longitude])
            setDefaultCenter([position.coords.latitude, position.coords.longitude])
            // editRoutePointAtIndexWithCoor(0, [position.coords.latitude, position.coords.longitude])
        }, error => {
            console.log("error on getting position")
            if (center) {
                setDefaultCenter(center)
            } else {
                if (gettingCenterStatus === "pending") {
                    setWaitingIP(true)
                } else {
                    const redSquareCoordinates = [55.7539242, 37.6207866] as [number, number]
                    setDefaultCenter(redSquareCoordinates)
                    setCenter(redSquareCoordinates, "default")
                }
            }
        })
    }, [])


    useEffect(() => {
        if (waitingIP && ((gettingCenterStatus === "success" && center) || gettingCenterStatus === "error")) {
            if (gettingCenterStatus === "success") {
                setDefaultCenter(center)
            } else {
                const redSquareCoordinates = [55.7539242, 37.6207866] as [number, number]
                setDefaultCenter(redSquareCoordinates)
                setCenter(redSquareCoordinates, "default")
            }
            setWaitingIP(false)
        }
    }, [gettingCenterStatus, center])


    return defaultCenter
}

export default useCenter