import React, {FC, ReactElement, ReactNode} from "react"
import {Grid, OutlinedInput, TextField, Typography} from "@mui/material"
import BlockContainer from "../BlockContainer"
import InputMask from "react-input-mask"

interface IPassengerBlock {
    name: string
    editName: (newName: string) => void
    phoneNumber: string
    editPhoneNumber: (newPhoneNumber: string) => void
    comment: string
    editComment: (newComment: string) => void
}

const PassengerBlock: FC<IPassengerBlock> = (props): ReactElement => {
    const {
        name, editName,
        phoneNumber, editPhoneNumber,
        comment, editComment
    } = props

    return (
        <>
            <BlockContainer item container direction={"column"} wrap={"nowrap"}>
                <Typography variant={"h5"}>Пассажир</Typography>
            </BlockContainer>
            <BlockContainer item container spacing={3}>
                <Grid item>
                    <TextField
                        label={"Имя"}
                        value={name}
                        onChange={e => editName(e.target.value)}
                    />
                </Grid>
                <Grid item>
                    <InputMask
                        mask="+7 (999) 999-9999"
                        value={phoneNumber}
                        onChange={e => editPhoneNumber(e.target.value)}
                        children={((inputProps: any) =>
                            <TextField
                                {...inputProps}
                                label={"Номер телефона"}
                                type="tel"
                            />) as unknown as ReactNode
                        }
                    />
                </Grid>
                <Grid item>
                    <TextField
                        value={comment}
                        onChange={e => editComment(e.target.value)}
                        label={"Комментарий"}
                        sx={{width: '400px'}}
                    />
                </Grid>
            </BlockContainer>
        </>
    )
}

export default PassengerBlock