import React, {FC, ReactElement, useEffect} from "react"
import {Theme, useMediaQuery} from "@mui/material"
import MainPageDesktop from "./MainPageDesktop"
import MainPageMobile from "./MainPageMobile"
import {useCreatedOrders, useOrganization, useProfile} from "@kashalot-web/react/dist"
import {GET_CREATED_ORDERS_REQ_ID, GET_EMPLOYEES_REQ_ID, UPDATE_ORG_REQ_ID} from "../../requestIDs"
import MainPageTablet from "./MainPageTablet"
import EmployeeTypes from "@kashalot-web/core/dist/TaxiInfo/EmployeeTypes"


const MainPage: FC = (): ReactElement => {
    const {
        getCreatedOrders, startListeningStatusChanged, stopListeningStatusChanged
    } = useCreatedOrders()
    const {userInfo} = useProfile()
    const {getEmployeesList, getOrganizationDetails} = useOrganization()

    useEffect(() => {
        getCreatedOrders(GET_CREATED_ORDERS_REQ_ID)
        startListeningStatusChanged()
        if (userInfo && [...EmployeeTypes.getBranchEmployees(), ...EmployeeTypes.getCompanyEmployees()]
            .map(e => e.id).includes(userInfo.employeeType.id)) {
            getOrganizationDetails(UPDATE_ORG_REQ_ID)
            getEmployeesList(GET_EMPLOYEES_REQ_ID)
        }
        return () => {
            stopListeningStatusChanged()
        }
    }, [])

    // const xl = useMediaQuery((theme: Theme) => theme.breakpoints.up('xl'))
    const lg = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'))
    // const md = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'))
    const sm = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'))
    // const xs = useMediaQuery((theme: Theme) => theme.breakpoints.up('xs'))

    if (lg) {
        return (
            <MainPageDesktop/>
        )
    }

    if (sm) {
        return (
            <MainPageTablet/>
        )
    }

    return (
        <MainPageMobile/>
    )
}

export default MainPage