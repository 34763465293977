import React, {FC, ReactElement} from "react"
import {Grid, IconButton, Typography} from "@mui/material"
import ReplayIcon from "@mui/icons-material/Replay"
import ShareIcon from "@mui/icons-material/Share"
import DeleteIcon from "@mui/icons-material/Delete"

interface IOrderCardCover {
    deadline: string
    bgcolor: string
    replay?: () => void
    share?: () => void
    cancel?: () => void
}

const OrderCardCover: FC<IOrderCardCover> = (props): ReactElement => {
    const {deadline, bgcolor, replay, share, cancel} = props

    return (
        <Grid
            container wrap={"nowrap"} justifyContent={"space-between"}
        >
            <Grid
                item container
                justifyContent={"space-between"} alignItems={"center"}
                sx={{
                    bgcolor: bgcolor,
                    p: '4px 16px'
                }}
            >
                <Grid item>
                    <Typography color={"white"} variant={"h5"}>
                        {deadline}
                    </Typography>
                </Grid>
                <Grid item>
                    {!!replay && (
                        <IconButton onClick={replay} sx={{color: 'white'}}>
                            <ReplayIcon/>
                        </IconButton>
                    )}
                    {!!share && (
                        <IconButton onClick={share} sx={{color: 'white'}}>
                            <ShareIcon/>
                        </IconButton>
                    )}
                    {!!cancel && (
                        <IconButton onClick={cancel} sx={{color: 'white'}}>
                            <DeleteIcon/>
                        </IconButton>
                    )}
                </Grid>
            </Grid>
        </Grid>
    )
}

export default OrderCardCover