import {FC, ReactElement} from "react"
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt"
import SwapHorizIcon from '@mui/icons-material/SwapHoriz'
import {Fab, Grid, Typography} from "@mui/material"

interface ISwapButton {
    onClick: () => void
    disabled?: boolean
}

const SwapButton: FC<ISwapButton> = ({onClick, disabled}): ReactElement => {
    return (
        <Grid sx={{position: 'relative'}}>
            <Fab
                color={"primary"} size={"small"}
                onClick={onClick}
                disabled={disabled}
                sx={{
                    opacity: disabled === true ? '0.6' : '1.0',
                    '.MuiSvgIcon-root': {
                        transition: (theme) => theme.transitions.create(
                            ['transform'],
                            {duration: theme.transitions.duration.complex}
                        )
                    },
                    '&:hover': disabled !== true ? {
                        bgcolor: 'primary.main',
                        '.MuiSvgIcon-root': {
                            transform: 'rotate(180deg)'
                        },
                    } : {}
                }}
            >
                <SwapHorizIcon />
            </Fab>
            <ArrowRightAltIcon
                sx={{
                    color: 'lightgray',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    fontSize: '120px',
                    opacity: '0.5'
                }}
            />
        </Grid>
    )
}

export default SwapButton