import {Theme} from "@mui/material"
import { makeStyles, createStyles } from '@mui/styles'

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            height: 'calc(var(--vh, 1vh) * 100)',
            background: theme.palette.primary.light
        },
        bottomPanel: {
            height: '80px',
            paddingBottom: '3px',
            // borderTop: '1px solid gray',
            // borderColor: theme.palette.grey["400"],
            // background: theme.palette.common.white,
        },
        ordersWindow: {
            overflow: 'hidden',
            height: '100vh',
            padding: '5px',
        },
        ordersContainer: {
            width: '100%',
            height: '100%',
            overflow: 'auto'
        }
    })
)