import React, {FC, ReactElement, useState} from "react"
import {CurtainBody, CurtainPanel, ICurtainPanel} from "../CurtainPanel"
import {Grid, Typography} from "@mui/material"

interface IOptionsPanel extends ICurtainPanel {
    selectedOptions: Array<Option | OptionWithPayload>
    onOptionsChange: (newOptions: Array<Option | OptionWithPayload>) => void
    taxiOptions: Array<Option | OptionWithPayload>
}

const OptionsPanel: FC<IOptionsPanel> = (props): ReactElement => {
    const {
        isOpen, open, close,
        selectedOptions, onOptionsChange, taxiOptions
    } = props

    return (
        <CurtainPanel
            open={open} close={close} isOpen={isOpen}
            iconContainerWidth="3.4rem"
            iconContainerHeight="2.7rem"
        >
            <CurtainBody>
                <Grid
                    container item
                    direction="column"
                    wrap="nowrap"
                >
                    {taxiOptions.map((taxiOption, index) => {
                        const isSelected = !!selectedOptions.find(selectedOption => selectedOption.id === taxiOption.id)
                        const typographyProps = isSelected ? {color: "common.white"} : {}
                        return (
                            <Grid
                                item key={`employee-${index}`}
                                onClick={() => {
                                    if (isSelected) {
                                        onOptionsChange(selectedOptions.filter(selectedOption => selectedOption.id !== taxiOption.id))
                                    } else {
                                        onOptionsChange([...selectedOptions, taxiOption])
                                    }
                                }}
                                sx={isSelected ? {bgcolor: 'primary.main'} : {}}
                            >
                                <Typography
                                    variant="body2"
                                    sx={{padding: '10px'}}
                                    {...typographyProps}
                                >
                                    {taxiOption.title}
                                </Typography>
                            </Grid>
                        )
                    })}
                </Grid>
            </CurtainBody>
        </CurtainPanel>
    )
}

export default OptionsPanel