import React, {FC, ReactElement, useState} from "react"
import {useStyles} from "./Styles"
import {
    Button,
    CircularProgress,
    Divider,
    Grid,
    MenuItem,
    Select,
    TextField,
    Typography
} from "@mui/material"
import CancelIcon from '@mui/icons-material/Cancel'
import Curtain from "../Curtain"
import Dot from "../../common/Dot"
import ModalFreeMembers from "./ModalFreeMembers"
import FastAndCheap from "../FastAndCheap"
import {ICurtainPanel} from "../CurtainPanel"
import CustomChip from "../CustomChip"
import OptionsPanel from "../NewOrderPanels/OptionsPanel"
import CarClassesPanel from "../NewOrderPanels/CarClassesPanel"

interface IDeliveryOrderPanel extends ICurtainPanel {
    deliveryOrder: IDeliveryOrder
    selectDeliveryOffer: (order: IDeliveryOrder, offer: IOffer, type: IOffersManager["selectedOfferType"]) => void
    setOfferDelay: (orderKey: string, offer: string, delay: number) => void
    freeMembers: Array<DeliveryMember>
    removeMember: (member: DeliveryMember) => void
    putMembers: (members: Array<DeliveryMember>) => void
    updateOrderTitle: (newTitle: string) => void
    updateOrderPhone: (newPhone: string) => void
    taxiClasses: Array<TaxiClass>
    selectTaxiClass: (newTaxiClass: TaxiClass) => void
    taxiOptions: Array<Option | OptionWithPayload>
    selectOptions: (newOptions: Array<Option | OptionWithPayload>) => void
}

const DeliveryOrderPanel: FC<IDeliveryOrderPanel> = (props): ReactElement => {
    const classes = useStyles()
    const {
        deliveryOrder, selectDeliveryOffer, setOfferDelay,
        taxiClasses, selectTaxiClass,
        taxiOptions, selectOptions,
        updateOrderTitle, updateOrderPhone,
        freeMembers, removeMember, putMembers,
        open, close, isOpen,
    }: IDeliveryOrderPanel = props

    // const selectedTaxiClass = taxiClasses.find((taxiClass => taxiClass.id === deliveryOrder.orderDetails.carClass.id))

    const [isOpenModal, setIsOpenModal] = useState<boolean>(false)
    const openModal = () => setIsOpenModal(true)
    const closeModal = () => setIsOpenModal(false)

    const [isOpenOptions, setIsOpenOptions] = useState<boolean>(false)
    const openOptions = () => setIsOpenOptions(true)
    const closeOptions = () => setIsOpenOptions(false)

    const [isOpenCarClass, setIsOpenCarClass] = useState<boolean>(false)
    const openCarClass = () => setIsOpenCarClass(true)
    const closeCarClass = () => setIsOpenCarClass(false)

    return (
        <>
            <ModalFreeMembers
                freeMembers={freeMembers}
                submit={putMembers}
                isOpen={isOpenModal}
                close={closeModal}
            />
            <OptionsPanel
                isOpen={isOpenOptions}
                open={openOptions}
                close={closeOptions}
                taxiOptions={taxiOptions}
                selectedOptions={deliveryOrder.offersManager.options}
                onOptionsChange={selectOptions}
            />
            <CarClassesPanel
                isOpen={isOpenCarClass}
                open={openCarClass}
                close={closeCarClass}
                taxiClasses={taxiClasses}
                selectedClass={deliveryOrder.orderDetails.carClass}
                selectCarClass={newCarClass => selectTaxiClass(newCarClass)}
            />
            <Curtain
                open={open}
                close={close}
                isOpen={isOpen}
            >
                <Grid
                    container item
                    className={classes.root}
                    direction="column"
                    wrap="nowrap"
                    justifyContent="space-between"
                >
                    <Grid
                        container item
                        className={classes.header}
                    >
                    </Grid>
                    <Grid
                        container item
                        // className={classes.body}
                        direction="column"
                        wrap="nowrap"
                        sx={{overflow: 'auto', bgcolor: 'white'}}
                    >
                        <Grid
                            item container
                            sx={{p: '4px 7px'}}
                        >
                            <TextField
                                autoComplete='off'
                                placeholder="Название заказа"
                                value={deliveryOrder.orderDetails.customerName}
                                onChange={(event) => updateOrderTitle(event.target.value)}
                                variant="standard"
                                fullWidth
                                multiline
                                // InputProps={{disableUnderline: true}}
                            />
                        </Grid>
                        <Grid
                            item container
                            sx={{p: '4px 7px'}}
                        >
                            <Select
                                value={deliveryOrder.orderDetails.customerPhoneNumber}
                                onChange={e => updateOrderPhone(e.target.value)}
                                variant="standard"
                                sx={{width: '100%'}}
                            >
                                {deliveryOrder.membersList.map((member, index) => (
                                    <MenuItem key={`select-${index}`} value={member.phone}>{member.phone}</MenuItem>
                                ))}
                            </Select>
                        </Grid>
                        <Grid item sx={{p: '2px 5px'}} container justifyContent={"center"}>
                            <Typography variant="body1">
                                {deliveryOrder.routeManager.routeInfo.travelLength} км.
                                / {deliveryOrder.routeManager.routeInfo.travelTime} мин.
                            </Typography>
                        </Grid>
                        <Grid
                            item container
                            direction="column"
                            wrap="nowrap"
                            sx={{p: '4px 7px'}}
                        >
                            {deliveryOrder.routeManager.route.map((routePoint, index) => {
                                const members = deliveryOrder.membersList.filter(member => member.address === routePoint.address)
                                return (
                                    <Grid
                                        key={`delivery-order-member-${index}`}
                                        container item
                                        justifyContent="space-between"
                                        alignItems="center"
                                        wrap="nowrap"
                                        sx={{p: '1px 0'}}
                                    >
                                        <Grid item sx={{mr: '7px'}}><Dot size="17px"/></Grid>
                                        <Grid
                                            item container
                                            direction="column"
                                            wrap="nowrap"
                                            onClick={() => index > 0 && removeMember(members[0])}
                                        >
                                            {index === 0 && (
                                                <Grid item>
                                                    <Typography variant="h5">
                                                        Адрес подачи
                                                    </Typography>
                                                </Grid>
                                            )}
                                            {members.map(member => {
                                                return (
                                                    <Grid item key={`route-point-${member.id}`}>
                                                        <Typography variant="h5">
                                                            {member.fio || member.email} x{member.amount}
                                                        </Typography>
                                                    </Grid>
                                                )
                                            })}
                                            <Grid item>
                                                <Typography variant="body2">
                                                    {routePoint.address.split(',').filter((_, i) => i < 2).join(',')}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid item>
                                            {index > 0 && <CancelIcon color="primary"/>}
                                        </Grid>
                                    </Grid>
                                )
                            })}
                            {freeMembers.length > 0 && (
                                <Grid item container justifyContent="center" sx={{p: '10px 0'}}>
                                    <Grid item>
                                        <Button
                                            variant="contained"
                                            size="small"
                                            onClick={openModal}
                                        >
                                            Посадить сотрудника
                                        </Button>
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>

                        <Divider/>

                        <Grid item container justifyContent={"center"} sx={{p: '4px 0'}}>
                            <Button onClick={openCarClass}>
                                {deliveryOrder.orderDetails.carClass.title}
                            </Button>
                        </Grid>

                        <Divider/>

                        {/*<Grid*/}
                        {/*    item container*/}
                        {/*    // wrap="nowrap"*/}
                        {/*    sx={{p: '9px'}}*/}
                        {/*    spacing={0.5}*/}
                        {/*>*/}
                        {/*    {taxiClasses*/}
                        {/*        .map((taxiClass: TaxiClass, index: number) => {*/}
                        {/*            const selected = taxiClass.id === deliveryOrder.orderDetails.carClass.id*/}
                        {/*            return (*/}
                        {/*                <Grid item key={`taxi-class-${index}`}>*/}
                        {/*                    <CustomChip*/}
                        {/*                        variant={selected ? "filled" : "outlined"}*/}
                        {/*                        onClick={() => selectTaxiClass(taxiClass)}*/}
                        {/*                    >{taxiClass.title}</CustomChip>*/}
                        {/*                </Grid>*/}
                        {/*            )*/}
                        {/*        })}*/}
                        {/*</Grid>*/}

                        {/*<Divider/>*/}

                        <Grid
                            item container
                            // wrap="nowrap"
                            justifyContent={"center"}
                            sx={{p: '9px'}}
                            spacing={0.5}
                        >
                            {deliveryOrder.offersManager.options.length > 0
                                ? deliveryOrder.offersManager.options.map((taxiOption: Option | OptionWithPayload, index: number) => {
                                    return (
                                        <Grid item key={`taxi-class-${index}`}>
                                            <CustomChip
                                                variant={"filled"}
                                                onClick={openOptions}
                                            >{taxiOption.title}</CustomChip>
                                        </Grid>
                                    )
                                })
                                : <Grid item>
                                    <Button onClick={openOptions}>
                                        Опции
                                    </Button>
                                </Grid>
                            }
                        </Grid>

                        <Divider/>

                        <FastAndCheap
                            order={deliveryOrder}
                            fastOffers={deliveryOrder.offersManager.getFastOffers(5)}
                            cheapOffers={deliveryOrder.offersManager.getCheapOffers(5)}
                            selectedOffers={deliveryOrder.offersManager.selectedOffers}
                            selectedOfferType={deliveryOrder.offersManager.selectedOfferType}
                            selectOffer={(offerID: string, type: IOffersManager["selectedOfferType"]) => {
                                const offer = deliveryOrder.offersManager.getOffers().find(o => o.id === offerID)
                                if (offer) {
                                    selectDeliveryOffer(deliveryOrder, offer, type)
                                }
                            }}
                            loading={deliveryOrder.status === "waiting-offers"}
                            setOfferDelay={(offerID, delay) => setOfferDelay(deliveryOrder.key, offerID, delay)}
                        />
                    </Grid>
                </Grid>
            </Curtain>
        </>
    )
}

export default DeliveryOrderPanel