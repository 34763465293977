import React, {FC, ReactElement} from "react"
import {Grid, Paper, Typography} from "@mui/material"
import Dot from "../../common/Dot"
import {IOrderCard} from "./Type"
import moment from "moment"
import Statuses from "@kashalot-web/core/dist/TaxiInfo/Statuses"

const OrderCardMobile: FC<IOrderCard> = ({order, onClick}): ReactElement => {
    let jamsTime: number = 0
    if (order.status.id === Statuses.Status8.id) {
        if (order.auto && order.auto.jamsTime) {
            jamsTime = Math.floor(order.auto.jamsTime / 60)
        } else {
            const minutes = Math.floor((order.auto?.jamsTime ||
                moment.duration(moment(order.deadline, "HH:mm DD.MM.YYYY").diff(moment())).asSeconds()) / 60)
            if (minutes > 0) {
                jamsTime = minutes
            }
        }
    }

    return (
        <Paper
            component={Grid}
            container
            direction="column"
            wrap="nowrap"
            onClick={onClick}
            sx={{p: '2vw 4vw', width: '100%'}}
        >
            <Grid item container wrap="nowrap" justifyContent="space-between" alignItems="center">
                <Grid item container direction="column" wrap="nowrap">
                    <Typography variant="subtitle1">{order.deadline}</Typography>
                    <Typography variant="body1">{order.title}</Typography>
                    <Typography variant="body1">
                        {order.employee?.fio || order.employee?.email}</Typography>
                </Grid>
                {/*<Grid item><Typography variant="h2">{producedOrder.orders[0].status.title}</Typography></Grid>*/}
                <Grid item><Typography variant="h4" align="right" noWrap={order.status.id !== Statuses.Status8.id && order.status.title.length < 10}>
                    {order.status.id === Statuses.Status8.id ? (
                        `Через ~ ${jamsTime} мин`
                    ) : (
                        order.status.title
                    )}
                </Typography></Grid>
            </Grid>
            <Grid item container direction={"column"} wrap={"nowrap"} sx={{m: '7px'}}>
                {order.route.map((address: string, routePointIndex: number) => {
                    return (
                        <Grid
                            key={`delivery-order-route-point-${routePointIndex}`}
                            item container
                            alignItems="center"
                            wrap="nowrap"
                        >
                            <Grid item sx={{mr: '7px'}}><Dot size="14px"/></Grid>
                            <Grid item>
                                <Typography variant="body2">
                                    {address.split(',').filter((_, i) => i < 2).join(',')}
                                </Typography>
                            </Grid>
                        </Grid>
                    )
                })}
            </Grid>
            <Grid item container direction="column" wrap="nowrap">
                <Grid item container justifyContent="space-between">
                    <Typography variant="body1">
                        {order.vendor.name + ' · ' +
                        order.carClass.title + ' · ' +
                        order.price + '₽'}
                    </Typography>
                </Grid>
            </Grid>
        </Paper>
    )
}

export default OrderCardMobile