import React, {FC, ReactElement} from "react"
import {Grid} from "@mui/material"

interface IDot {
    size: string
}
const Dot: FC<IDot> = ({size}): ReactElement => {
    return (
        <Grid sx={{
            maxWidth: size, minWidth: size,
            maxHeight: size, minHeight: size,
            bgcolor: 'primary.main',
            borderRadius: '50px',
        }}/>
    )
}

export default Dot