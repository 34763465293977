import {Theme} from "@mui/material"
import {makeStyles, createStyles} from '@mui/styles'

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            height: "100%",
            zIndex: theme.zIndex.drawer + 1000,
        },
        header: {
            backgroundColor: theme.palette.primary.main,
            "& .MuiInputBase-root": {
                color: 'white',
                fontWeight: 300
            }
        },
        body: {
            backgroundColor: 'white',
            flexGrow: 3
        },
        // main panel
        mainPanel: {
            position: "absolute",
            bottom: "0",
            overflow: 'hidden'
        },
        bottomBlock: {
            padding: "20px"
        },
        orderButton: {
            width: '78%',
        },
        backdrop: {
            // zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
        iconContainer: {
            maxWidth: '10vw',
            minWidth: '10vw',
            maxHeight: '10vw',
            minHeight: '10vw',
            position: 'relative',
            overflow: 'hidden',
        },
        selectedIconContainer: {
            // '&:after': {
            //     content: '""',
                position: "absolute",
                display: 'block',
                width: '100%',
                height: '5px',
                backgroundColor: theme.palette.primary.main,
                bottom: 0,
            // }
        },
        icon: {
            width: '80%',
            height: '80%'
        }
    })
)