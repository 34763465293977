import {FC, ReactElement} from "react"
import SaveIcon from '@mui/icons-material/Save'
import {Fab} from "@mui/material"

interface ISaveButton {
    onClick: () => void
    disabled?: boolean
}

const SaveButton: FC<ISaveButton> = ({onClick, disabled}): ReactElement => {
    return (
        <Fab
            color={"primary"} size={"small"}
            onClick={onClick}
            disabled={disabled}
            sx={{
                '.MuiSvgIcon-root': {
                    transition: (theme) => theme.transitions.create(
                        ['transform'],
                        {duration: theme.transitions.duration.complex}
                    )
                },
                '&:hover': disabled !== true ? {
                    bgcolor: 'primary.main',
                    '.MuiSvgIcon-root': {
                        transform: 'rotate(90deg)'
                    },
                } : {}
            }}
        >
            <SaveIcon />
        </Fab>
    )
}

export default SaveButton