import React, {FC, ReactElement, Ref, useEffect, useState} from "react"
import {Grid} from "@mui/material"
import LocationOnIcon from '@mui/icons-material/LocationOn'

type IYMapsBG = {
    map: any,
    setMap: (ref: Ref<any>) => void
    defaultCenter: [number, number]
    isActive: boolean
    onCenterChange: (newCenterCoordinates: [number, number]) => void
    resetAddress: () => void
    points?: Array<{
        coordinates: [number, number],
        type: "home" | "work" | "employee_home",
        onClick?: () => void,
        placeholder?: string,
        active?: boolean
    }>
    geoPosition?: (coordinates: [number, number]) => void
}

const YMapsBG: FC<IYMapsBG> = (props): ReactElement => {
    const {map, setMap, isActive, defaultCenter, onCenterChange, resetAddress, points, geoPosition} = props
    const [isMoved, setIsMoved] = useState<boolean>(false)
    const [location, setLocation] = useState<[number, number] | null>(null)
    const [locationLink, setLocationLink] = useState<any>(null)
    const [pointLinks, setPointLinks] = useState<Array<any>>([])


    useEffect(() => {
        let newMap: any
        try {
            // @ts-ignore
            if (ymaps) {
                // @ts-ignore
                newMap = new ymaps.Map("map", {
                    center: defaultCenter,
                    zoom: 12
                })
                newMap.controls.remove('searchControl')
                newMap.controls.remove('trafficControl')
                newMap.controls.remove('typeSelector')
                newMap.controls.remove('fullscreenControl')
                newMap.controls.remove('zoomControl')
                newMap.controls.remove('smallZoomControl')
                newMap.controls.remove('geolocationControl')
                setMap(newMap)
            }
        } catch (e) {
        }

        const watchID = navigator.geolocation.watchPosition(position => {
            setLocation([position.coords.latitude, position.coords.longitude])
        })

        return () => {
            navigator.geolocation.clearWatch(watchID)
            if (newMap) {
                setMap(null)
                newMap.destroy()
            }
        }
    }, [])


    useEffect(() => {
        if (map) {
            let newLinks: Array<any> = []
            pointLinks.forEach(pointLink => {
                map.geoObjects.remove(pointLink)
            })
            // new Promise(r => setTimeout(() => r(""), 500))
            //     .then(() => {
                    if (points) {
                        newLinks = points.map(item => {
                            const params1: any = {}
                            const params2: any = {}
                            if (item.type === "home") {
                                params2.preset = "islands#homeCircleIcon"
                            } else if (item.type === "work") {
                                params2.preset = "islands#pocketCircleIcon"
                            } else if (item.type === "employee_home") {
                                params1.iconCaption = item.placeholder
                                params2.preset = "islands#personCircleIcon"
                                params2.iconCaptionMaxWidth = '80'
                            } else {
                                return
                            }
                            params2.iconColor = "#864BFF"
                            if (item.active === false) {
                                params2.iconColor = "#BEBEBE"
                            }
                            // @ts-ignore
                            const placemark = new ymaps.Placemark(item.coordinates, params1, params2)
                            if (item.onClick) {
                                placemark.events.add('click', item.onClick)
                            }
                            // @ts-ignore
                            map.geoObjects.add(placemark)
                            return placemark
                        })
                    }
                    setPointLinks(l => newLinks)
                // })
        }
    }, [points, map])


    useEffect(() => {
        if (map) {
            map.setCenter(defaultCenter)
        }
    }, [defaultCenter])


    useEffect(() => {
        if (geoPosition && location) {
            geoPosition(location)
        }
        if (map) {
            if (locationLink) {
                map.geoObjects.remove(locationLink)
            }
            // @ts-ignore
            const newLink = new ymaps.Placemark(location, {}, {
                preset: 'islands#personCircleIcon',
                iconColor: '#864BFF'
            })
            // @ts-ignore
            map.geoObjects.add(newLink)
            setLocationLink(newLink)
        }
    }, [location])


    useEffect(() => {
        if (map) {
            if (isActive) {
                map.events.add(["actionbegin"], () => {
                    setIsMoved(true)
                    if (resetAddress) {
                        resetAddress()
                    }
                })
                map.events.add(["actionend"], () => {
                    onCenterChange(map.getCenter())
                    setIsMoved(false)
                })
            } else {
                // map.events.group().removeAll()
            }
        }
    }, [isActive])

    return (
        <Grid
            container item
            sx={{
                width: "100%",
                height: "100%",
                overflow: "hidden",
            }}
        >
            <div id="map" style={{width: '100%', height: '100%'}}/>
            {/*{myMap !== null && (<div id="map" />)}*/}
            <Grid
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)"
                }}
            >
                {isActive && (
                    <>
                        <LocationOnIcon
                            sx={{
                                color: "primary.main",
                                position: "absolute",
                                top: "50%", left: "50%",
                                transform: `translate(-50%, calc(-90% - ${isMoved ? 20 : 0}%))`,
                                transition: (theme) => theme.transitions.create(
                                    ['transform'],
                                    {duration: theme.transitions.duration.complex}
                                ),
                                zIndex: '10',
                                fontSize: '60px'
                            }}
                        />
                        <Grid
                            sx={{
                                width: '6px', height: '6px',
                                transform: 'scaleX(2)',
                                bgcolor: 'black', opacity: '0.5',
                                borderRadius: '100px',
                                zIndex: '5'
                            }}
                        />
                    </>
                )}
            </Grid>
            <Grid item container sx={{
                position: 'absolute',
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                bgcolor: 'rgba(255,255,255,.2)',
                pointerEvents: 'none'
            }}/>
        </Grid>
    )
}

export default YMapsBG