import {FC, ReactElement} from "react"
import OffersBlock from "../../../../LocalUICore/desktop/NewOrderBlocks/OffersBlock"
import {useNewOrder} from "@kashalot-web/react/dist"

const OffersBlockContainer: FC = (): ReactElement => {
    const {order, selectOffer} = useNewOrder()

    return (
        <OffersBlock
            order={order}
            offers={order.offersManager.getOffers()}
            selectedOffers={order.offersManager.selectedOffers}
            selectOffer={offer => selectOffer(offer.id, "cheap")}
            loading={order.status === "waiting-offers"}
        />
    )
}

export default OffersBlockContainer