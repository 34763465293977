import React, {FC, ReactElement, ReactNode, useState} from "react"
import BlockContainer from "../BlockContainer"
import {Typography, Grid, Autocomplete, TextField, CircularProgress} from "@mui/material"
import InputMask from "react-input-mask"
import {AddressInput} from "@kashalot-web/ui/dist"
import CrossButton from "../CrossButton"
import EmployeeTypes from "@kashalot-web/core/dist/TaxiInfo/EmployeeTypes"
import {makeID} from "@kashalot-web/core/dist/Utils/config"
import SaveButton from "../SaveButton"

const emptyEmployee: Employee = {
    _type: "employee",
    id: "",
    fio: "",
    email: "Новый адрес",
    active: true,
    wishlist: null,
    phone: "",
    type: EmployeeTypes.CompanyWorker,
}

const getShortAddress = (address: string) => address.split(',').filter((a, i) => i < 2).join(',')

interface IEmployeeForm {
    employeesList: Array<Employee>
    member: DeliveryMember | null
    updateMember: (member: DeliveryMember) => void
    removeMember: (member: DeliveryMember) => void
    suggestionsRequests: SuggestionsRequest[]
    pushSuggestionRequest: (id: string, inputValue: string) => void
    getFullEmployeeByID?: (employeeID: string) => Promise<Employee | null>
}

const EmployeeForm: FC<IEmployeeForm> = (props): ReactElement => {
    const {
        employeesList, member, updateMember, removeMember, getFullEmployeeByID,
        suggestionsRequests, pushSuggestionRequest
    } = props

    const [loading, setLoading] = useState<boolean>(false)
    const [newMember, setNewMember] = useState<DeliveryMember | null>(null)

    const handleSelectEmployee = async (employee: Employee) => {
        const member: DeliveryMember = {
            ...employee,
            address: '',
            coordinates: null,
            amount: 1,
            isNew: false,
            key: makeID(10)
        }

        let fio = employee.fio
        if (!fio) {
            fio = employee.email || ""
        }
        member.fio = fio

        let home: string = ""
        if (employee.id) {
            if (!employee.wishlist || employee.wishlist.length === 0) {
                if (getFullEmployeeByID) {
                    setLoading(true)
                    const fullEmployee = await getFullEmployeeByID(employee.id)
                    setLoading(false)
                    if (fullEmployee?.wishlist) {
                        home = fullEmployee.wishlist.find(item => item.type === "home")?.address || ""
                    }
                }
            } else {
                home = employee.wishlist.find(item => item.type === "home")?.address || ""
            }
        }
        member.address = home

        let id: string = employee.id
        if (!id) {
            id = makeID(10)
        }
        member.id = id

        if (member.address) {
            updateMember(member)
        } else {
            setNewMember(member)
        }
    }

    return (
        <Grid container spacing={2} alignItems={"center"}>
            {!!newMember && (
                <Grid item container>
                    <Typography>Черновик нового адреса (заполните все поля и сохраните)</Typography>
                </Grid>
            )}
            <Grid item>
                {(newMember || member) ? (
                    <TextField
                        value={newMember?.fio || member?.fio}
                        label={"Имя"}
                        sx={{width: 260}}
                        onChange={e => {
                            if (newMember) {
                                setNewMember({...newMember, fio: e.target.value})
                            } else if (member) {
                                updateMember({...member, fio: e.target.value})
                            }
                        }}
                    />
                ) : (
                    <Autocomplete
                        options={employeesList}
                        isOptionEqualToValue={(option, value) => option.id === value?.id}
                        getOptionLabel={option => {
                            if (option.fio) {
                                return `${option.fio} (${option.email})`
                            }
                            return option.email
                        }}
                        value={newMember || member}
                        onChange={(e, value) => {
                            if (value) {
                                handleSelectEmployee(value)
                            }
                        }}
                        sx={{width: 260}}
                        disabled={employeesList.length === 0}
                        loading={loading}
                        renderInput={(params) => {
                            return (
                                <TextField
                                    {...params}
                                    InputProps={{
                                        ...params.InputProps,
                                        sx: {bgcolor: 'white'},
                                        endAdornment: (
                                            <>
                                                {loading ? <CircularProgress color="inherit" size={20}/> : null}
                                                {params.InputProps.endAdornment}
                                            </>
                                        ),
                                    }}
                                    label={"Cотрудник"}
                                />
                            )
                        }}
                    />
                )}

            </Grid>
            <Grid item>
                <InputMask
                    mask="+7 (999) 999-9999"
                    value={newMember?.phone || member?.phone || ""}
                    onChange={e => {
                        if (newMember) {
                            setNewMember({...newMember, phone: e.target.value})
                        } else if (member) {
                            updateMember({...member, phone: e.target.value})
                        }
                    }}
                    disabled={!member && !newMember}
                    children={((inputProps: any) =>
                        <TextField
                            {...inputProps}
                            label={"Номер телефона"}
                            type="tel"
                            disabled={!member && !newMember}
                        />) as unknown as ReactNode}
                />
            </Grid>
            <Grid item>
                {(!!member || !!newMember) ? (
                    <AddressInput
                        id={`employee-${member?.id || newMember?.id}-address`}
                        placeholder={"Введите адрес"}
                        label={"Пункт назначения"}
                        defaultValue={newMember?.address || member?.address || ""}
                        onChange={newAddress => {
                            if (newMember) {
                                let fio = newMember.fio
                                if (newMember.fio === emptyEmployee.email || !newMember.fio) {
                                    fio = getShortAddress(newAddress)
                                }
                                let email = newMember.email
                                if (newMember.email === emptyEmployee.email || !newMember.email) {
                                    email = getShortAddress(newAddress)
                                }
                                console.log(fio, email)
                                setNewMember({...newMember, address: newAddress, fio, email})
                            } else if (member) {
                                updateMember({...member, address: newAddress})
                            }
                        }}
                        suggestionsRequests={suggestionsRequests}
                        pushSuggestionRequest={pushSuggestionRequest}
                    />
                ) : (
                    <TextField disabled={true} sx={{width: 300}} label={"Пункт назначения"}/>
                )}
            </Grid>
            <Grid item>
                <TextField
                    name={`amount-pass-${member?.id || "unknown"}`}
                    type={"number"}
                    disabled={!newMember && !member}
                    value={newMember?.amount || member?.amount || 1}
                    label={"Пассажиров"}
                    sx={{width: '110px'}}
                    onChange={e => {
                        if (member) {
                            try {
                                const num = Number.parseInt(e.target.value)
                                if (num > 0) {
                                    if (newMember) {
                                        setNewMember({...newMember, amount: num})
                                    } else if (member) {
                                        updateMember({...member, amount: num})
                                    }
                                }
                            } catch (e) {
                            }
                        }
                    }}
                />
            </Grid>
            <Grid item>
                <CrossButton
                    onClick={() => {
                        if (newMember) {
                            setNewMember(null)
                        } else if (member) {
                            removeMember(member)
                        }
                    }}
                />
            </Grid>
            {!!newMember && (
                <Grid item>
                    <SaveButton
                        onClick={() => {
                            if (newMember.address && newMember.fio) {
                                updateMember(newMember)
                                setNewMember(null)
                            }
                        }}
                        disabled={!newMember.address || !newMember.fio || newMember.phone.match(/\d/g)?.join("").length !== 11}
                    />
                </Grid>
            )}
        </Grid>
    )
}

interface IInputMembersBlock {
    employeesList: Array<Employee>
    members: Array<DeliveryMember>
    updateMember: (member: DeliveryMember) => void
    removeMember: (member: DeliveryMember) => void
    suggestionsRequests: SuggestionsRequest[]
    pushSuggestionRequest: (id: string, inputValue: string) => void
    getFullEmployeeByID?: (employeeID: string) => Promise<Employee | null>
}

const InputMembersBlock: FC<IInputMembersBlock> = (props): ReactElement => {
    const {
        employeesList,
        members, updateMember, removeMember, getFullEmployeeByID,
        suggestionsRequests, pushSuggestionRequest
    } = props

    return (
        <>
            <BlockContainer container>
                <Typography variant={"h5"}>Адреса</Typography>
            </BlockContainer>
            <BlockContainer container direction={"column"} wrap={"nowrap"} spacing={2}>
                {[...members, null].map((member, ind) => {
                    return (
                        <Grid item container key={`member-in-delivery-${ind}`}>
                            <EmployeeForm
                                employeesList={employeesList}
                                member={member}
                                updateMember={updateMember}
                                removeMember={removeMember}
                                suggestionsRequests={suggestionsRequests}
                                pushSuggestionRequest={pushSuggestionRequest}
                                getFullEmployeeByID={getFullEmployeeByID}
                            />
                        </Grid>
                    )
                })}
                <Grid item container>

                </Grid>
            </BlockContainer>
        </>
    )
}

export default InputMembersBlock