import React, {FC, ReactElement} from "react"
import useNewOrder from "@kashalot-web/react/dist/useNewOrder"
import {TaxiOptions} from "@kashalot-web/core/dist/TaxiInfo/TaxiOptions"
import {ICurtainPanel} from "../../../../LocalUICore/mobile/CurtainPanel"
import OptionsPanel from "../../../../LocalUICore/mobile/NewOrderPanels/OptionsPanel"


interface IOptionsPanelContainer extends ICurtainPanel {
}

const OptionsPanelContainer: FC<IOptionsPanelContainer> = ({isOpen, open, close}): ReactElement => {
    const {order, setOptions} = useNewOrder()

    return (
        <OptionsPanel
            isOpen={isOpen}
            open={open}
            close={close}
            taxiOptions={TaxiOptions.getAllOptions()}
            selectedOptions={order.offersManager.options}
            onOptionsChange={newOptions => setOptions(newOptions)}
        />
    )
}

export default OptionsPanelContainer