import React, {FC, ReactElement} from "react"
import {CurtainBody, CurtainPanel} from "../CurtainPanel"
import {Divider, Grid, Radio, Typography} from "@mui/material"

interface IDeadlinePanel {
    open: () => void
    close: () => void
    isOpen: boolean
    isUrgent: boolean
    deadline: any
    selectIsUrgent: () => void
    selectDeadline: () => void
}

const DeadlinePanel: FC<IDeadlinePanel> = (props): ReactElement => {
    const {
        open, close, isOpen,
        isUrgent, deadline, selectIsUrgent, selectDeadline
    }: IDeadlinePanel = props

    return (
        <CurtainPanel
            open={open} close={close} isOpen={isOpen}
        >
            <CurtainBody height='15rem'>
                <Grid
                    item container
                    direction="column"
                    wrap="nowrap"
                >
                    <Grid
                        item container
                        wrap="nowrap"
                        alignItems="center"
                        onClick={selectIsUrgent}
                    >
                        <Grid item>
                            <Radio checked={isUrgent}/>
                        </Grid>
                        <Grid item>
                            <Typography
                                variant="body1"
                                sx={{padding: '10px'}}
                            >
                                На ближайшее время
                            </Typography>
                        </Grid>
                    </Grid>
                    <Divider />
                    <Grid
                        item container
                        wrap="nowrap"
                        alignItems="center"
                        onClick={selectDeadline}
                    >
                        <Grid item>
                            <Radio checked={!isUrgent}/>
                        </Grid>
                        <Grid item>
                            <Typography
                                variant="body1"
                                sx={{padding: '10px'}}
                            >
                                К определенному времени: <br/> {deadline.format("HH:mm DD.MM.YYYY")}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Divider />
                </Grid>
            </CurtainBody>
        </CurtainPanel>
    )
}

export default DeadlinePanel