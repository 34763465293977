import {FC, ReactElement, useEffect, useState} from "react"
import {Grid, Typography} from "@mui/material"
import moment from "moment"

const Clock: FC = (): ReactElement => {
    const [labelTime, setLabelTime] = useState<string>(moment().format("DD MMMM • HH:mm"))

    useEffect(() => {
        const timeInterval = setInterval(() => {
            setLabelTime(moment().format("DD MMMM • HH:mm"))
        }, 1000 * 30)
        return () => {
            clearInterval(timeInterval)
        }
    }, [])

    return (
        <Grid item container direction={"column"} wrap={"nowrap"}>
            <Typography variant={"body2"}>Сегодня</Typography>
            <Typography variant={"h3"} noWrap={true}>{labelTime}</Typography>
        </Grid>
    )
}

export default Clock