import {FC, ReactElement} from "react"
import PaymentBlock from "../../../../LocalUICore/desktop/NewOrderBlocks/PaymentBlock"
import PaymentTypes from "@kashalot-web/core/dist/TaxiInfo/PaymentTypes"
import {useNewOrder} from "@kashalot-web/react/dist"

const PaymentBlockContainer: FC = (): ReactElement => {
    const {order, editOrderDetail} = useNewOrder()

    return (
        <PaymentBlock
            paymentType={order.offersManager.paymentType}
            paymentTypes={PaymentTypes.getAllPaymentTypes()}
            onChangePayment={newPayment => editOrderDetail("paymentType", newPayment)}
            cashback={order.offersManager.cashback}
            onChangeCashback={newCashback => editOrderDetail("cashback", newCashback)}
        />
    )
}

export default PaymentBlockContainer