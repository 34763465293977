import {FC, ReactElement} from "react"
import OrganizationSelectorBlock from "../../../../LocalUICore/desktop/NewDeliveryBlocks/OrganizationSelectorBlock"
import {useDispatcher, useDispatcherRequest, useNewDelivery} from "@kashalot-web/react/dist"
import {GET_CLIENTS_REQ_ID} from "../../../../requestIDs"

const OrganizationSelectorBlockContainer: FC = (): ReactElement => {
    const {clientsManager} = useDispatcher()
    const [status] = useDispatcherRequest(GET_CLIENTS_REQ_ID)
    const {delivery, updateDeliveryDetail} = useNewDelivery()

    return (
        <OrganizationSelectorBlock
            organization={delivery.organization}
            organizations={status === "pending" ? [] : clientsManager?.getOrganizations() || []}
            selectOrganization={newOrg => updateDeliveryDetail("organization", newOrg)}
            loading={status === "pending"}
        />
    )
}

export default OrganizationSelectorBlockContainer