import {FC, ReactElement} from "react"
import {FormControl, InputLabel, MenuItem, Select} from "@mui/material"

export interface IPortersInput {
    porters: number
    onAmountPortersChange: (newPortersAmount: number) => void
}

const PortersInput: FC<IPortersInput> = ({porters, onAmountPortersChange}): ReactElement => {
    return (
        <FormControl>
            <InputLabel id="taxi-classes">Грузчики</InputLabel>
            <Select
                labelId="taxi-classes"
                label="Грузчики"
                value={porters}
                onChange={e => {
                    try {
                        const value = e.target.value
                        if (typeof value === "number") {
                            onAmountPortersChange(value)
                        } else {
                            const amount = Number.parseInt(value)
                            onAmountPortersChange(amount)
                        }
                    } catch (e) {
                    }
                }}
                sx={{width: '200px'}}
            >
                <MenuItem key={`porters-amount-0`} value={0}>Справлюсь сам</MenuItem>
                <MenuItem key={`porters-amount-0`} value={1}>Помощь водителя</MenuItem>
                <MenuItem key={`porters-amount-1`} value={2}>Водитель и 1 грузчик</MenuItem>
                <MenuItem key={`porters-amount-2`} value={3}>Водитель и 2 грузчика</MenuItem>
            </Select>
        </FormControl>
    )
}

export default PortersInput