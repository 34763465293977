import React, {FC, PropsWithChildren, ReactElement} from "react"
import {Grid, Typography} from "@mui/material"

interface IChip {
    variant: "filled" | "outlined"
    onClick?: () => void
}

const CustomChip: FC<PropsWithChildren<IChip>> = ({variant, children, onClick}): ReactElement => {
    const bgcolor = variant === "filled" ? 'primary.main' : ''
    const typographyColor = variant === "filled" ? 'common.white' : 'primary.main'
    return (
        <Grid
            item
            sx={{
                bgcolor: bgcolor,
                border: '1px solid black',
                borderColor: 'primary.main',
                borderRadius: '100px',
                p: '3px 6px',
                transition: (theme) => theme.transitions.create(
                    ['background-color'],
                    {duration: theme.transitions.duration.complex}
                )
            }}
            onClick={onClick}
        >
            <Typography
                variant="body1" noWrap color={typographyColor}
                sx={{
                    transition: (theme) => theme.transitions.create(
                        ['color'],
                        {duration: theme.transitions.duration.complex}
                    )
                }}
            >
                {children}
            </Typography>
        </Grid>
    )
}

export default CustomChip