import {FC, ReactElement} from "react"
import BackdropContainer from "../../../LocalUICore/common/BackdropContainer"
import {useNewOrder} from "@kashalot-web/react/dist"

const HandlerOrderBackdrop: FC = (): ReactElement => {
    const {order} = useNewOrder()

    return (
        <BackdropContainer open={order.status === "ordering"}/>
    )
}

export default HandlerOrderBackdrop