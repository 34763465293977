import {FC, ReactElement} from "react"
import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";

export interface ICourierClassInput {
    selectedCourierClass: CourierClass
    allCourierClasses: Array<CourierClass>
    onCourierClassChange: (newCourierClass: CourierClass) => void
}

const CourierClassInput: FC<ICourierClassInput> = ({selectedCourierClass, allCourierClasses, onCourierClassChange}): ReactElement => {
    return (
        <FormControl>
            <InputLabel id="taxi-classes">Тип курьера</InputLabel>
            <Select
                labelId="taxi-classes"
                label="Тип курьера"
                value={selectedCourierClass.id}
                onChange={e => {
                    const newCarClass = allCourierClasses.find(carClass => carClass.id === e.target.value)
                    if (newCarClass) {
                        onCourierClassChange(newCarClass)
                    }
                }}
                sx={{width: '200px'}}
            >
                {allCourierClasses.map((taxiClass, index) => {
                    return (
                        <MenuItem key={`taxi-class-${index}`} value={taxiClass.id}>{taxiClass.title}</MenuItem>
                    )
                })}
            </Select>
        </FormControl>
    )
}

export default CourierClassInput