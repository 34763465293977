import {FC, ReactElement} from "react"
import RouteBlock from "../../../../LocalUICore/desktop/NewOrderBlocks/RouteBlock"
import {useMap, useNewOrder} from "@kashalot-web/react/dist"

const getShortAddress = (address: string) => address.split(',').filter((a, i) => i < 2).join(',')

const RouteBlockContainer: FC = (): ReactElement => {
    const {
        order, editOrderDetail,
        editRoutePointAtIndex, insertEmptyRoutePointAtIndex, deleteRoutePointAtIndex, swapRoutePoints
    } = useNewOrder()
    const {suggestionsRequests, pushSuggestionRequest} = useMap()

    return (
        <RouteBlock
            route={order.routeManager.route.map(point => point.address)}
            travelLength={order.routeManager.routeInfo.travelLength}
            travelTime={order.routeManager.routeInfo.travelTime}
            onChangeByIndex={(index, newAddress) => {
                if (newAddress || order.routeManager.route.length <= 2) {
                    if (newAddress && index >= order.routeManager.route.length - 1 && !order.orderDetails.customerName) {
                        editOrderDetail("customerName", getShortAddress(newAddress))
                    }
                    editRoutePointAtIndex(index, newAddress)
                } else {
                    deleteRoutePointAtIndex(index)
                }
            }}
            pushEmptyPoint={() => insertEmptyRoutePointAtIndex(order.routeManager.route.length)}
            suggestionsRequests={suggestionsRequests}
            pushSuggestionRequest={pushSuggestionRequest}
            swapRoutePoints={swapRoutePoints}
        />
    )
}

export default RouteBlockContainer