import React, {FC, ReactElement} from "react"
import {Grid, Paper, Typography} from "@mui/material"

interface IPanelButton {
    icon: ReactElement
    onClick: () => void
    disable: boolean
    label: string
}

const PanelButton: FC<IPanelButton> = ({icon, onClick, disable, label}): ReactElement => {
    return (
        <Paper
            component={Grid}
            elevation={3}
            item container
            sx={{width: '23%', bgcolor: 'primary.main', borderRadius: '10px', p: '9px 0', opacity: disable ? '0.6' : '1.0'}}
            direction="column"
            alignItems="center"
            wrap="nowrap"
            onClick={onClick}
        >
            <Grid item>{icon}</Grid>
            <Grid item>
                <Typography variant="body2" align="center" color="white">
                    {label}
                </Typography>
            </Grid>
        </Paper>
    )
}

export default PanelButton