import React, {FC, ReactElement, useEffect, useState} from "react"
import {useStyles} from "./Styles"
import {useNavigate} from "react-router-dom"
import OrderPanel from "../../../LocalUICore/mobile/OrderPanel"
import SideBar from "../../../LocalUICore/mobile/SideBar"
import {Grid, Typography} from "@mui/material"
import SearchInput from "../../../LocalUICore/mobile/inputs/SearchInput"
import {Skeleton} from "@mui/material"
import DeliveryCardMobile from "../../../LocalUICore/mobile/OrderCards/DeliveryCardMobile"
import OrderCardMobile from "../../../LocalUICore/mobile/OrderCards/OrderCardMobile"
import PanelButton from "../../../LocalUICore/mobile/PanelButton"
import DeliveryDiningIcon from "@mui/icons-material/DeliveryDining"
import LocalTaxiIcon from "@mui/icons-material/LocalTaxi"
import PeopleAltIcon from "@mui/icons-material/PeopleAlt"
import {useOrganization, useProfile, useCreatedOrders} from "@kashalot-web/react"
import useSearchOrders from "../../../LocalUICore/common/SearchOrdersHook"
import Statuses from "@kashalot-web/core/dist/TaxiInfo/Statuses"
import CustomChip from "../../../LocalUICore/mobile/CustomChip"
import useOpener from "../../../LocalUICore/useOpener"
import {useOrdersRequest} from "@kashalot-web/react/dist"
import {GET_CREATED_ORDERS_REQ_ID} from "../../../requestIDs"
import OrdersBatchCard from "../../../LocalUICore/mobile/OrderCards/OrdersBatchCard";


const CANCEL_ORDER_REQ_ID = "cancel-order-asdlkfnasdf"

const paddings = {
    p: 'calc(var(--vh, 1vh) * 0.65) 0',
}


const MainPageMobile: FC = (): ReactElement => {
    const classes = useStyles()
    const navigate = useNavigate()
    const [ordersListType, setOrdersListType] = useState<"main" | "completed" | "canceled">("main")
    const {employeesManager} = useOrganization()
    const {logout, userInfo} = useProfile()
    const {cancelOrder, activeOrders, completedOrders, canceledOrders, getCreatedOrders} = useCreatedOrders()
    const [statusOrdersReq] = useOrdersRequest(GET_CREATED_ORDERS_REQ_ID)
    const pending: boolean = statusOrdersReq === "pending"

    let orderList: Array<CreatedOrder | CreatedOrdersBatch> = []
    if (ordersListType === "main") {
        orderList = activeOrders
    } else if (ordersListType === "completed") {
        orderList = completedOrders
    } else if (ordersListType === "canceled") {
        orderList = canceledOrders
    }

    const [searchInputValue, setSearchInputValue] = useState<string>("")
    const filteredOrders = useSearchOrders(orderList, searchInputValue)

    const [isOpenSideBar, openSideBar, closeSideBar] = useOpener(false)

    const [targetOrder, setTargetOrder] = useState<CreatedOrder | null>(null)
    const [isOpenOrderPanel, openOrderPanel, closeOrderPanel] = useOpener(false)

    useEffect(() => closeOrderPanel(), [orderList])

    useEffect(() => {
        const event = () => {
            if (document.hidden){
                // Вкладка не активна
            } else {
                // Вкладка активна
                getCreatedOrders(GET_CREATED_ORDERS_REQ_ID)
            }
        }

        document.addEventListener("visibilitychange", event)

        return () => {
            document.removeEventListener("visibilitychange", event)
        }
    }, [])

    let candidate: Employee | null = null
    if (employeesManager && userInfo) {
        candidate = employeesManager.getActiveEmployees().find(employee => employee.id === userInfo.userID) || null
    }

    return (
        <>
            <OrderPanel
                open={openOrderPanel}
                close={closeOrderPanel}
                isOpen={isOpenOrderPanel}
                order={targetOrder}
                validatorForCanceling={(order: CreatedOrder) => !!Statuses.ableToDelete().find(status => status.id === order.status.id)}
                cancelOrder={(order: CreatedOrder) => cancelOrder(order.id, CANCEL_ORDER_REQ_ID)}
            />
            <SideBar
                isOpen={isOpenSideBar}
                open={openSideBar}
                close={closeSideBar}
                employee={candidate}
                logout={logout}
            />
            <Grid
                container
                direction="column"
                justifyContent="space-between"
                wrap="nowrap"
                className={classes.root}
                sx={{p: 'calc(var(--vh, 1vh) * 1)'}}
            >
                <Grid
                    item container
                    sx={{height: '60px', ...paddings}}
                >
                    <SearchInput
                        openFilterModals={openSideBar}
                        inputValue={searchInputValue}
                        handleInputValueChange={(newValue: string) => setSearchInputValue(newValue)}
                    />
                </Grid>
                <Grid
                    item container sx={{...paddings}}
                    justifyContent="space-around"
                >
                    <CustomChip
                        variant={ordersListType === "main" ? "filled" : "outlined"}
                        onClick={() => setOrdersListType("main")}
                    >
                        Активные
                    </CustomChip>
                    <CustomChip
                        variant={ordersListType === "completed" ? "filled" : "outlined"}
                        onClick={() => setOrdersListType("completed")}
                    >
                        Завершенные
                    </CustomChip>
                    <CustomChip
                        variant={ordersListType === "canceled" ? "filled" : "outlined"}
                        onClick={() => setOrdersListType("canceled")}
                    >
                        Отмененные
                    </CustomChip>
                </Grid>
                <Grid
                    container item
                    className={classes.ordersWindow}
                    sx={{...paddings}}
                >
                    {pending && (
                        <Grid
                            container
                            direction="column"
                            wrap="nowrap"
                            justifyContent="space-between"
                            className={classes.ordersContainer}
                        >
                            <Skeleton variant="rectangular" width={"100%"} height={"24%"}/>
                            <Skeleton variant="rectangular" width={"100%"} height={"24%"}/>
                            <Skeleton variant="rectangular" width={"100%"} height={"24%"}/>
                            <Skeleton variant="rectangular" width={"100%"} height={"24%"}/>
                        </Grid>
                    )}
                    {!pending && filteredOrders.length === 0 && (
                        <Grid item container justifyContent="center">
                            <Grid item>
                                <Typography variant="h4">
                                    Ничего не найдено :(
                                </Typography>
                            </Grid>
                        </Grid>
                    )}
                    {!pending && filteredOrders.length > 0 && (
                        <Grid
                            container
                            direction="column"
                            wrap="nowrap"
                            className={classes.ordersContainer}
                        >
                            {filteredOrders.map((producedOrder: CreatedOrder | CreatedOrdersBatch, index: number) => {
                                return (
                                    <Grid
                                        container item
                                        key={`produced-order-${index}`}
                                        sx={{
                                            margin: '1.5vw 0',
                                            "&:first-of-type": {mt: '0'},
                                            "&:last-of-type": {mb: '1px'}
                                        }}
                                    >
                                        {producedOrder._type === "created_order" && (
                                            <OrderCardMobile
                                                order={producedOrder}
                                                cancelOrder={() => 42}
                                                onClick={() => {
                                                    setTargetOrder(producedOrder)
                                                    openOrderPanel()
                                                }}
                                            />
                                        )}
                                        {producedOrder._type === "created_orders_batch" && (
                                            <OrdersBatchCard
                                                ordersBatch={producedOrder}
                                                onClickOrder={order => {
                                                    setTargetOrder(order)
                                                    openOrderPanel()
                                                }}
                                            />
                                        )}
                                    </Grid>
                                )
                            })}
                        </Grid>
                    )}
                </Grid>
                <Grid
                    item container
                    alignItems={"center"}
                    justifyContent={"space-around"}
                    className={classes.bottomPanel}
                    sx={{...paddings}}
                >
                    <PanelButton
                        icon={<DeliveryDiningIcon sx={{color: 'white'}}/>}
                        onClick={() => 42}
                        disable={true}
                        label="Доставка"
                    />
                    <PanelButton
                        icon={<LocalTaxiIcon sx={{color: 'white'}}/>}
                        onClick={() => navigate("/create_order")}
                        disable={false}
                        label="Заказать"
                    />
                    <PanelButton
                        icon={<PeopleAltIcon sx={{color: 'white'}}/>}
                        onClick={() => navigate("/create_delivery")}
                        disable={false}
                        label="Развозка"
                    />
                </Grid>
            </Grid>
        </>
    )
}

export default MainPageMobile