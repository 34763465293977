import {FC, ReactElement} from "react"
import {FormControl, InputLabel, MenuItem, Select} from "@mui/material"

export interface IAutoCourierTypeInput {
    autoCourierType: AutoCourierType
    onChangeAutoCourierType: (newAutoCourierType: AutoCourierType) => void
}

const AutoCourierTypeInput: FC<IAutoCourierTypeInput> = ({autoCourierType, onChangeAutoCourierType}): ReactElement => {
    return (
        <FormControl>
            <InputLabel id="taxi-classes">Тип автокурьера</InputLabel>
            <Select
                labelId="taxi-classes"
                label="Тип автокурьера"
                value={autoCourierType}
                onChange={e => {
                    const value = e.target.value
                    if (value === "flat") {
                        onChangeAutoCourierType('flat')
                    } else if (value === 'entrance') {
                        onChangeAutoCourierType('entrance')
                    }
                }}
                sx={{width: '200px'}}
            >
                <MenuItem key={`auto-courier-entrance`} value={'entrance'}>До подъезда</MenuItem>
                <MenuItem key={`auto-courier-flat`} value={'flat'}>До двери</MenuItem>
            </Select>
        </FormControl>
    )
}

export default AutoCourierTypeInput