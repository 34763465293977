import React, {FC, ReactElement, useState} from "react"
import {Chip, Grid, Skeleton, TextField, Typography} from "@mui/material"
import OrderCardDesktop from "../OrderCardDesktop"
import {makeID} from "@kashalot-web/core/dist/Utils/config"
import OrdersBatchCardDesktop from "../OrderCardDesktop/OrdersBatchCardDesktop"

interface IOrdersBlock {
    actor: "dispatcher" | "client"
    orderCardsType: "short" | "full"
    orders: Array<CreatedOrder | CreatedOrdersBatch>
    loading: boolean
    share?: (order: CreatedOrder) => void
    cancel?: (orderID: string) => void
    replay?: (order: CreatedOrder) => void
}

const OrdersOutput: FC<IOrdersBlock> = (props): ReactElement => {
    const {
        actor, orderCardsType,
        orders, cancel, replay, share,
        loading
    } = props

    // const share = (order: CreatedOrder) => {
    //     if (navigator.share) {
    //         navigator.share({
    //             title: order.title,
    //             text: '',
    //             url: 'https://mobile.kashalot.me/order/' + order.link,
    //         })
    //             .then(() => console.log('Successful share'))
    //             .catch((error) => console.log('Error sharing', error))
    //     } else {
    //         console.log('Share not supported on this browser, do it the old way.')
    //     }
    // }

    const [randomKey] = useState<string>(makeID(10))

    return (
        <>
            {loading && (
                <Grid item container direction={"column"} wrap={"nowrap"} sx={{height: '100%'}}
                      justifyContent={"space-between"}>
                    <Skeleton variant="rectangular" width={"100%"} height={"31%"}/>
                    <Skeleton variant="rectangular" width={"100%"} height={"31%"}/>
                    <Skeleton variant="rectangular" width={"100%"} height={"31%"}/>
                </Grid>
            )}
            {!loading && orders.length === 0 && (
                <Grid item container justifyContent={"center"}>
                    <Typography variant={"h4"}>Пусто :(</Typography>
                </Grid>
            )}
            {!loading && orders.length > 0 && (
                <Grid item container direction={"column"} wrap={"nowrap"} sx={{maxHeight: '100%', overflow: 'scroll'}}>
                    {
                        orders.map((order, ind) => {
                            if (order._type === "created_order") {
                                return (
                                    <Grid item key={`order-key-${randomKey}-${ind}`} sx={{mb: '30px'}}>
                                        <OrderCardDesktop
                                            order={order}
                                            share={!!share ? (() => share(order)) : undefined}
                                            cancel={!!cancel ? (() => cancel(order.id)) : undefined}
                                            replay={!!replay ? (() => replay(order)) : undefined}
                                            type={orderCardsType}
                                            actor={actor}
                                        />
                                    </Grid>
                                )
                            } else if (order._type === "created_orders_batch") {
                                return (
                                    <Grid item key={`order-key-${randomKey}-${ind}`} sx={{mb: '30px'}}>
                                        <OrdersBatchCardDesktop
                                            order={order}
                                            type={orderCardsType}
                                            actor={actor}
                                            replay={!!replay && order.orders.length > 0 ? (() => replay(order.orders[0])) : undefined}
                                            cancel={cancel}
                                        />
                                    </Grid>
                                )
                            }
                        })
                    }
                </Grid>
            )}
        </>
    )
}

export default OrdersOutput