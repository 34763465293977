import {FC, ReactElement} from "react"
import CompanyEmployeeBlock from "../../../../LocalUICore/desktop/NewOrderBlocks/CompanyEmployeeBlock"
import {useDispatcher, useDispatcherRequest, useNewOrder} from "@kashalot-web/react/dist"
import {GET_CLIENTS_REQ_ID} from "../../../../requestIDs"

const CompanyEmployeeBlockContainer: FC = (): ReactElement => {
    const {clientsManager} = useDispatcher()
    const [status] = useDispatcherRequest(GET_CLIENTS_REQ_ID)
    const {order, editOrderDetail} = useNewOrder()

    return (
        <CompanyEmployeeBlock
            loading={status === "pending"}
            organization={order.orderDetails.organization}
            organizations={status === "pending" ? [] : clientsManager?.getOrganizations() || []}
            onChangeOrganization={org => editOrderDetail("organization", org)}
            employee={order.orderDetails.employee}
            employees={order.orderDetails.organization && clientsManager?.getEmployeesByOrganizationID(order.orderDetails.organization.id, true) || []}
            onChangeEmployee={employee => {
                editOrderDetail("employee", employee)
                if (employee) {
                    editOrderDetail("customerPhoneNumber", employee.phone)
                }
            }}
        />
    )
}

export default CompanyEmployeeBlockContainer