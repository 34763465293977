import {FC, ReactElement} from "react"
import FilingAddressBlock from "../../../../LocalUICore/desktop/NewDeliveryBlocks/FilingAddressBlock"
import {useMap, useNewDelivery} from "@kashalot-web/react/dist"

const FilingAddressBlockContainer: FC = (): ReactElement => {
    const {delivery, updateDeliveryDetail} = useNewDelivery()
    const {pushSuggestionRequest, suggestionsRequests} = useMap()

    return (
        <FilingAddressBlock
            comment={delivery.comment}
            editComment={newComment => updateDeliveryDetail("comment", newComment)}
            address={delivery.startingAddress}
            onChangeAddress={newAddress => updateDeliveryDetail("startAddress", newAddress)}
            suggestionsRequests={suggestionsRequests}
            pushSuggestionRequest={pushSuggestionRequest}
        />
    )
}

export default FilingAddressBlockContainer