import React, {FC, ReactElement, useEffect, useState} from "react"
import {Grid, TextField} from "@mui/material"
import {CurtainBody, CurtainHeader, CurtainPanel, ICurtainPanel} from "../CurtainPanel"
import AddressesSuggestions from "../AddressesSuggestions"
import BackspaceIcon from '@mui/icons-material/Backspace'
import AddBoxIcon from '@mui/icons-material/AddBox'
import RoutPointInput from "../inputs/RoutPointInput"

interface IRoutePanel extends ICurtainPanel {
    route: Array<RoutePoint>
    editInputValueAtIndex: (id: string, newValue: string) => void
    deleteRoutePoint: (index: number) => void
    insertFieldByID: (index: number) => void
    selectRoutePointVariant: (routePointIndex: number, newAddress: string) => void
    comment: string
    editComment: (newComment: string) => void
    suggestionsRequests: Array<SuggestionsRequest>
    goToMap: (index: number) => void
}

const RoutePanel: FC<IRoutePanel> = (props): ReactElement => {
    const {
        isOpen, open, close,
        route, editInputValueAtIndex, deleteRoutePoint, insertFieldByID, goToMap, // pushRoutePoint,
        selectRoutePointVariant, suggestionsRequests
    }: IRoutePanel = props
    const [activeIDTextField, setActiveIDTextField] = useState<string>('')
    const [activeIndexTextField, setActiveIndexTextField] = useState<number>(-1)

    useEffect(() => setTextFieldValues(route.map((routePoint: RoutePoint) => routePoint.address)), [route])

    const [textFieldValues, setTextFieldValues] = useState<Array<string>>(route.map((routePoint: RoutePoint) => routePoint.address))
    const handleTextFieldValueUpdate = (index: number, newValue: string) => {
        const newTextFieldValues = textFieldValues.slice()
        newTextFieldValues.splice(index, 1, newValue)
        setTextFieldValues(newTextFieldValues)
    }
    const handleInsertField = (index: number) => {
        insertFieldByID(index)
    }
    const handleRoutePointDelete = (index: number) => {
        if (route.length <= 2) {
            selectRoutePointVariant(index, "")
        } else {
            deleteRoutePoint(index)
            setTextFieldValues(textFieldValues.filter((_, i) => i !== index))
        }
    }

    const handleAddressSelected = (newAddress: string) => {
        selectRoutePointVariant(activeIndexTextField, newAddress)
        editInputValueAtIndex(activeIDTextField, newAddress)
        handleTextFieldValueUpdate(activeIndexTextField, newAddress)
    }

    return (
        <CurtainPanel
            open={open} close={close} isOpen={isOpen}
        >
            <CurtainHeader>
                <Grid
                    sx={{
                        bgcolor: 'primary.main',
                        "& .MuiInputBase-root": {
                            color: 'white',
                            fontWeight: 400
                        }
                    }}
                    item container
                    direction="column"
                >
                    {
                        textFieldValues.map((address: string, routePointIndex: number, arr) => {
                            const id = `create-order-route-point-${routePointIndex}`
                            let placeholder: string = ''
                            if (routePointIndex === 0) {
                                placeholder = 'Откуда'
                            } else if (routePointIndex === route.length - 1) {
                                placeholder = 'Куда'
                            } else {
                                placeholder = 'Через'
                            }
                            return (
                                <Grid
                                    item container wrap="nowrap"
                                    key={`create-order-route-point-${routePointIndex}`}
                                    sx={{padding: '2px 20px'}}
                                >
                                    <RoutPointInput
                                        value={address}
                                        onChange={(newValue) => {
                                            editInputValueAtIndex(id, newValue)
                                            handleTextFieldValueUpdate(routePointIndex, newValue)
                                        }}
                                        placeholder={placeholder}
                                        addField={() => handleInsertField(routePointIndex + 1)}
                                        deleteField={() => handleRoutePointDelete(routePointIndex)}
                                        goToMap={() => goToMap(routePointIndex)}
                                        onFocus={() => {
                                            setActiveIndexTextField(routePointIndex)
                                            setActiveIDTextField(id)
                                        }}
                                        onBlur={() => {
                                            setTimeout(() => {
                                                setActiveIndexTextField(-1)
                                                setActiveIDTextField('')
                                            }, 100)
                                        }}
                                    />
                                </Grid>
                            )
                        })
                    }
                </Grid>
            </CurtainHeader>
            <CurtainBody height='22rem'>
                <Grid
                    container item
                    direction="column"
                    wrap="nowrap"
                >
                    <AddressesSuggestions
                        activeAddressInput={activeIndexTextField !== -1}
                        suggestionsRequest={suggestionsRequests.find(suggestionsRequest => suggestionsRequest.id === activeIDTextField) || null}
                        setDestination={handleAddressSelected}
                    />
                </Grid>
            </CurtainBody>
        </CurtainPanel>
    )
}

export default RoutePanel