import React, {FC, ReactElement, useEffect} from "react"
import {Grid} from "@mui/material"
import EmployeeBlockContainer from "./ContainerBlocks/EmployeeBlockContainer"
import RouteBlockContainer from "./ContainerBlocks/RouteBlockContainer"
import TitleBlock from "../../../LocalUICore/desktop/NewOrderBlocks/TitleBlock"
import {useNavigate} from "react-router-dom"
import OffersBlockContainer from "./ContainerBlocks/OffersBlockContainer"
import OrderButtonBlockContainer from "./ContainerBlocks/OrderButtonBlockContainer"
import AutoBlockContainer from "./ContainerBlocks/AutoBlockContainer"
import PassengerBlockContainer from "./ContainerBlocks/PassengerBlockContainer"
import PaymentBlockContainer from "./ContainerBlocks/PaymentBlockContainer"
import {BlockContainerWithSidePaddings} from "../../../LocalUICore/desktop/BlockContainer"
import HandlerOrderBackdrop from "./HandlerOrderBackdrop"
import {useNewOrder, useProfile} from "@kashalot-web/react/dist"
import EmployeeTypes from "@kashalot-web/core/dist/TaxiInfo/EmployeeTypes"
import CompanyEmployeeBlockContainer from "./ContainerBlocks/CompanyEmployeeBlockContainer"
import {useSnackbar} from "notistack"

const CreateOrderDesktop: FC = (): ReactElement => {
    const navigate = useNavigate()
    const {userInfo} = useProfile()
    const {order, resetOrder} = useNewOrder()
    const {enqueueSnackbar} = useSnackbar()

    useEffect(() => {
        if (order.status === "success") {
            enqueueSnackbar('Заказа успешно создан', {variant: 'success'})
            resetOrder()
            navigate('/')
        } else if (order.status === "error" && order.error === "ordering-err") {
            enqueueSnackbar('Ошибка при создании заказа', {variant: 'error'})
        }
    }, [order.status])

    return (
        <Grid container direction={"column"} wrap={"nowrap"}>
            <HandlerOrderBackdrop/>

            <BlockContainerWithSidePaddings
                item container
                direction={"column"} wrap={"nowrap"}
                sx={{bgcolor: 'primary.dark'}}
            >
                <TitleBlock title={"Создание заказа"} close={() => navigate('/')}/>
                {userInfo
                && [EmployeeTypes.Dispatcher.id, EmployeeTypes.DispatcherWorker.id].includes(userInfo.employeeType.id)
                && (
                    <CompanyEmployeeBlockContainer/>
                )}
                {userInfo
                && [...EmployeeTypes.getBranchEmployees(), ...EmployeeTypes.getCompanyEmployees()]
                    .map(e => e.id).includes(userInfo.employeeType.id)
                && (
                    <EmployeeBlockContainer/>
                )}
                <RouteBlockContainer/>
            </BlockContainerWithSidePaddings>
            <BlockContainerWithSidePaddings item container direction={"column"} wrap={"nowrap"}>
                <AutoBlockContainer/>
            </BlockContainerWithSidePaddings>
            <BlockContainerWithSidePaddings item container direction={"column"} wrap={"nowrap"}
                                            sx={{bgcolor: 'primary.light'}}>
                <OffersBlockContainer/>
            </BlockContainerWithSidePaddings>
            <BlockContainerWithSidePaddings item container direction={"column"} wrap={"nowrap"}>
                <PassengerBlockContainer/>
            </BlockContainerWithSidePaddings>
            <BlockContainerWithSidePaddings item container direction={"column"} wrap={"nowrap"}
                                            sx={{bgcolor: 'primary.light'}}>
                <PaymentBlockContainer/>
            </BlockContainerWithSidePaddings>
            <OrderButtonBlockContainer/>
        </Grid>
    )
}

export default CreateOrderDesktop