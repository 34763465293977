import {FC, ReactElement} from "react"
import {Routes, Route, Navigate } from 'react-router-dom'

import LoginPage from "../pages/LoginPage"
import MainPage from "../pages/MainPage"
import CreateOrderPage from "../pages/CreateOrderPage"
import CreateDeliveryPage from "../pages/CreateDeliveryPage"
import {useProfile} from "@kashalot-web/react/dist"
import OrderByLinkPage from "../pages/OrderByLinkPage"
import CreateShippingPage from "../pages/CreateShippingPage";

const RoutesManager: FC = (): ReactElement => {
    const {userInfo} = useProfile()
    const isAuthenticated = !!userInfo

    if (isAuthenticated) {
        return (
            <Routes>
                <Route path="/" element={<MainPage />} />
                <Route path="/create_order" element={<CreateOrderPage />} />
                <Route path="/create_delivery" element={<CreateDeliveryPage />} />
                <Route path={"/create_shipping"} element={<CreateShippingPage />}/>
                <Route path="/order/:link" element={<OrderByLinkPage />} />
                <Route
                    path="*"
                    element={<Navigate to="/" replace />}
                />
            </Routes>
        )
    }

    return (
        <Routes>
            <Route path="/login" element={<LoginPage />} />
            <Route path="/order/:link" element={<OrderByLinkPage />} />
            <Route
                path="*"
                element={<Navigate to="/login" replace />}
            />
        </Routes>
    )
}

export default RoutesManager