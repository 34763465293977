import React, {FC, ReactElement} from "react"
import {CurtainBody, CurtainPanel, ICurtainPanel} from "../CurtainPanel"
import {Divider, Grid, OutlinedInput, Radio, TextField, Typography} from "@mui/material"

interface IDelayedOptionsPanel extends ICurtainPanel {
    delayedOptions: DelayedFeedOptions | null
    onChangeDelayedOptions: (newOptions: DelayedFeedOptions | null) => void
}

const DelayedOptionsPanel: FC<IDelayedOptionsPanel> = (props): ReactElement => {
    const {delayedOptions, onChangeDelayedOptions} = props

    return (
        <CurtainPanel {...props}>
            <CurtainBody>
                <Grid
                    container item
                    direction="column"
                    wrap="nowrap"
                >
                    <Grid
                        item container alignItems={"center"}
                        onClick={() => onChangeDelayedOptions(null)}
                    >
                        <Grid item>
                            <Radio checked={!delayedOptions}/>
                        </Grid>
                        <Grid item>
                            <Typography>Отключить</Typography>
                        </Grid>
                    </Grid>
                    <Divider />
                    <Grid
                        item container
                        direction={"column"} wrap={"nowrap"}
                    >
                        <Grid
                            item container
                            alignItems={"center"}
                            onClick={() => onChangeDelayedOptions({
                                maxDelayTime: 15,
                                ratePriceDiff: 15,
                                minPriceDiff: 150
                            })}
                        >
                            <Grid item>
                                <Radio checked={!!delayedOptions}/>
                            </Grid>
                            <Grid item>
                                <Typography>Включить</Typography>
                            </Grid>
                        </Grid>
                        <Grid
                            item container
                            direction={"column"} wrap={"nowrap"}
                            sx={{p: '0 1rem'}}
                            spacing={1}
                        >
                            <Grid item>
                                <Typography variant="subtitle1">
                                    Отложенное время подачи, мин
                                </Typography>
                            </Grid>
                            <Grid item>
                                <OutlinedInput
                                    fullWidth
                                    type={"number"}
                                    value={delayedOptions?.maxDelayTime || 0}
                                    onChange={e => {
                                        if (delayedOptions) {
                                            const value = e.target.value
                                            try {
                                                const numb = Number.parseInt(value)
                                                onChangeDelayedOptions({...delayedOptions, maxDelayTime: numb})
                                            } catch (e) {
                                            }
                                        }
                                    }}
                                    disabled={!delayedOptions}
                                    readOnly={!delayedOptions}
                                />
                            </Grid>
                            <Grid item>
                                <Typography variant="subtitle1">
                                    Разница в цене, %
                                </Typography>
                            </Grid>
                            <Grid item>
                                <OutlinedInput
                                    fullWidth
                                    type={"number"}
                                    value={delayedOptions?.ratePriceDiff || 0}
                                    onChange={e => {
                                        if (delayedOptions) {
                                            const value = e.target.value
                                            try {
                                                const numb = Number.parseInt(value)
                                                onChangeDelayedOptions({...delayedOptions, ratePriceDiff: numb})
                                            } catch (e) {
                                            }
                                        }
                                    }}
                                    disabled={!delayedOptions}
                                    readOnly={!delayedOptions}
                                />
                            </Grid>
                            <Grid item>
                                <Typography variant="subtitle1">
                                    Мнимальная разница, руб
                                </Typography>
                            </Grid>
                            <Grid item>
                                <OutlinedInput
                                    fullWidth
                                    type={"number"}
                                    value={delayedOptions?.minPriceDiff || 0}
                                    onChange={e => {
                                        if (delayedOptions) {
                                            const value = e.target.value
                                            try {
                                                const numb = Number.parseInt(value)
                                                onChangeDelayedOptions({...delayedOptions, minPriceDiff: numb})
                                            } catch (e) {
                                            }
                                        }
                                    }}
                                    disabled={!delayedOptions}
                                    readOnly={!delayedOptions}
                                />
                            </Grid>
                            <Grid item sx={{height: '16px'}} />
                        </Grid>
                    </Grid>
                </Grid>
            </CurtainBody>
        </CurtainPanel>
    )
}

export default DelayedOptionsPanel