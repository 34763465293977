import {FC, ReactElement} from "react"
import {Autocomplete, Grid, TextField, Typography} from "@mui/material"
import BlockContainer from "../BlockContainer"

interface IEmployeeBlock {
    employees: Array<Employee>
    selectedEmployee: Employee | null
    onChange: (newEmployee: Employee | null) => void
}

const EmployeeBlock: FC<IEmployeeBlock> = ({employees, selectedEmployee, onChange}): ReactElement => {
    return (
        <>
            <BlockContainer item container sx={{bgcolor: 'primary.dark'}}>
                <Typography color={"white"} variant={"h5"}>Сотрудник</Typography>
            </BlockContainer>
            <BlockContainer item container sx={{bgcolor: 'primary.dark'}}>
                <Autocomplete
                    disablePortal
                    value={selectedEmployee}
                    isOptionEqualToValue={(option, value) => option.id === value?.id}
                    options={employees}
                    onChange={(e, value) => onChange(value)}
                    getOptionLabel={option => {
                        if (option.fio) {
                            return `${option.fio} (${option.email})`
                        }
                        return option.email
                    }}
                    sx={{width: 300}}
                    renderInput={(params) => {
                        return (
                            <TextField
                                {...params}
                                InputProps={{...params.InputProps, sx: {bgcolor: 'white'}}}
                                placeholder={"Введите имя сотрудника"}
                            />
                        )
                    }}
                />
            </BlockContainer>
        </>
    )
}

export default EmployeeBlock