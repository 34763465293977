import {FC, ReactElement, ReactNode} from "react"
import {Chip, Fab, Grid} from "@mui/material"

interface IIconButtonWithLabel {
    icon: ReactElement
    onClick: () => void
    labels: Array<ReactNode>
}

const IconButtonWithLabel: FC<IIconButtonWithLabel> = ({icon, labels, onClick}): ReactElement => {
    return (
        <Grid
            item container
            wrap="nowrap"
            // alignItems="center"
            sx={{m: '3px'}}
        >
            <Grid item>
                <Fab size="small" color="primary" onClick={onClick} sx={{pointerEvents: 'auto'}}>
                    {icon}
                </Fab>
            </Grid>
            <Grid item container>
                {labels.map((label, index) => {
                    return (
                        <Chip
                            key={`label-${index}`}
                            label={label}
                            variant="outlined"
                            style={{backgroundColor:'white'}}
                            sx={{ml: '0.4rem', mb: '0.2rem', pointerEvents: 'auto'}}
                            onClick={onClick}
                        />
                    )
                })}
            </Grid>
        </Grid>
    )
}

export default IconButtonWithLabel