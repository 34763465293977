import React, {FC, ReactElement, useEffect, useState} from "react"
import RoutePanel from "../../../../LocalUICore/mobile/NewOrderPanels/RoutePanel"
import {useNewOrder, useMap} from "@kashalot-web/react"

interface IRoutePanelContainer {
    isOpen: boolean,
    open: () => void
    close: () => void

    goToMap: (ind: number) => void
}

const RoutePanelContainer: FC<IRoutePanelContainer> = (props): ReactElement => {
    const {isOpen, open, close, goToMap} = props

    const {
        order,
        editOrderDetail,
        editRoutePointAtIndex,
        insertEmptyRoutePointAtIndex,
        deleteRoutePointAtIndex
    } = useNewOrder()
    const {suggestionsRequests, pushSuggestionRequest} = useMap()

    const [lastState, setLastState] = useState<boolean>(false)
    const [flag, setFlag] = useState<boolean>(false)

    useEffect(() => {
        if (flag && order.routeManager.routeReady()) {
            close()
            setFlag(false)
        }
    }, [order.routeManager.route])

    const getShortAddress = (address: string) => address.split(',').filter((a, i) => i < 2).join(',')

    return (
        <RoutePanel
            isOpen={isOpen}
            open={open}
            close={() => {
                close()
                setLastState(!lastState)
            }}
            route={order.routeManager.route}
            deleteRoutePoint={(index: number) => deleteRoutePointAtIndex(index)}
            insertFieldByID={ind => insertEmptyRoutePointAtIndex(ind)}
            comment={order.orderDetails.customerComment}
            editComment={(newComment: string) => editOrderDetail("customerComment", newComment)}
            editInputValueAtIndex={(id: string, newValue: string) => pushSuggestionRequest(id, newValue)}
            suggestionsRequests={suggestionsRequests}
            selectRoutePointVariant={(routePointIndex: number, newAddress: string) => {
                if (routePointIndex === order.routeManager.route.length - 1) {
                    editOrderDetail("customerName", getShortAddress(newAddress))
                }
                editRoutePointAtIndex(routePointIndex, newAddress)
                setFlag(true)
            }}
            goToMap={goToMap}
        />
    )
}

export default RoutePanelContainer