import {FC, ReactElement} from "react"
import BlockContainer from "../BlockContainer"
import {FormControl, Grid, InputLabel, MenuItem, Select, Typography} from "@mui/material"

interface IPaymentBlock {
    paymentType: PaymentType
    paymentTypes: Array<PaymentType>
    onChangePayment: (newPayment: PaymentType) => void
    cashback: number
    onChangeCashback: (newCashback: number) => void
}

const PaymentBlock: FC<IPaymentBlock> = (props): ReactElement => {
    const {
        paymentType, paymentTypes, onChangePayment,
        cashback, onChangeCashback
    } = props

    return (
        <>
            <BlockContainer item container sx={{bgcolor: 'primary.light'}}>
                <Typography variant={"h5"}>Оплата</Typography>
            </BlockContainer>
            <BlockContainer container sx={{bgcolor: 'primary.light'}}>
                <Grid item container spacing={3}>
                    <Grid item>
                        <FormControl>
                            <InputLabel id="payment-type">Тип оплаты</InputLabel>
                            <Select
                                labelId="payment-type"
                                label="Тип оплаты"
                                value={paymentType.id}
                                onChange={e => {
                                    const newPayment = paymentTypes.find(paymentType => paymentType.id === e.target.value)
                                    if (newPayment) {
                                        onChangePayment(newPayment)
                                    }
                                }}
                                sx={{width: '250px'}}
                            >
                                {paymentTypes.map((paymentType, index) => {
                                    return (
                                        <MenuItem key={`payment-type-${index}`}
                                                  value={paymentType.id}>{paymentType.title}</MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <FormControl>
                            <InputLabel id="cashback">Договор</InputLabel>
                            <Select
                                labelId="cashback"
                                label="Договор"
                                value={cashback}
                                onChange={e => {
                                    try {
                                        const num = Number.parseInt(e.target.value.toString())
                                        onChangeCashback(num)
                                    } catch (e) {
                                    }
                                }}
                                sx={{width: '250px'}}
                            >
                                <MenuItem value={0}>Корпоративный</MenuItem>
                                <MenuItem value={15}>Агентский</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            </BlockContainer>
        </>
    )
}

export default PaymentBlock